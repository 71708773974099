<template>
  <div>
    <div v-if="model===false">
      <div class="total" ref="total">
        <div class="topback"  :style="{ height: '7vh', backgroundColor: scrollback ? 'rgb(196,196,196)' : 'transparent' ,position:'fixed'}">
          <headtip @showDialog="showPartner"></headtip>
        </div>
        <div class="screen1">
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <div class="up" v-if="scrollTrue===false" @click="uptop"></div>
          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">Oil & Gas Generic AI</div>
            <div class="title2">Professional And Open AI Cloud Platform For The Global Oil & Gas Industry</div>
            <div class="title3" style="margin-top: 0vh">
              <div class="hoverTitle"   @click="position1">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                Oil & Gas Information GPTs
              </div>
              <div class="hoverTitle"  @click="position7">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                Oil & Gas Professional GPTs
              </div>
              <div class="hoverTitle"  @click="position5">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                Enterprise Management GPTs
              </div>
              <div class="hoverTitle"  @click="position2">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                General GPTs
              </div>


            </div>
            <div class="title3">
              <div class="hoverTitle"  @click="position8">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                GPT Application Services For Oil & Gas Development Operations
              </div>
              <div class="hoverTitle"   @click="position6">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                GPT Application Services For Smart Oilfield Solutions
              </div>
              <div class="hoverTitle"   @click="position9">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                Intelligent Multimedia Services
              </div>
            </div>
            <div class="title3">

              <div class="hoverTitle"   @click="position3">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                AI Universal Training And Transformation Consulting Services
              </div>
              <div class="hoverTitle"   @click="position10">
                <div style="height: 0.8vw;width: 0.8vw;margin: 0.1vw 0.3vw"><img src="@/assets/circlr.png"></div>
                Oil & Gas Enterprise Self-built GPT Cloud Platform Services
              </div>

            </div>
          </div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!--    油气咨询-->
        <div class="screenConsult" ref="oilZixun">
          <div class="title" style="text-align: center">Oil & Gas Information GPTs</div>
          <div class="light" style="width: fit-content">
            We provide users with free online Oil & Gas information services based on AI large model technology.
            <span class="light2"><br>Since the AI model cannot be completely accurate and is limited by computing power，if you want to get better results, please<span   @click="clickConsult('Oil & Gas Information GPTs')" style="color: #2F6EFF;cursor: pointer">&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro2">
            <!--        知识-->
            <div class="grand2" @click="info">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/icon03.png"/>
                </div>
                <div class="title1">Oil & Gas Information</div>
                <div class="title2">
                  Oil & Gas Information covers comprehensive industry information, serving as your intelligent assistant.
                </div>
                <div class="consult" @click="info">View Details<i
                    class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>
            <!--            展馆-->
            <div class="grand2" @click="expo">
              <div class="back">
                <div class="photo"><img src="@/assets/icon01.png"/></div>
                <div class="title1">Oil & Gas Exposition</div>
                <div class="title2">
                  Oil & Gas Exposition provides online promotion and exhibition services for companies in the global Oil & Gas development ecosystem.
                </div>
                <div class="consult" @click="expo">View Details<i
                    class="el-icon-right" style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>

            <!--        人才-->
            <div class="grand2" @click="job">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/icon02.png"/>
                </div>
                <div class="title1">Oil & Gas JobAI</div>
                <div class="title2">
                  Oil & Gas JobAI is committed to becoming a global job hunting and recruitment platform for professionals in the Oil & Gas industry.
                </div>
                <div class="consult" @click="job">View Details<i class="el-icon-right"
                                                                 style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>


          </div>

        </div>

        <!--    油气专业类GPTs    -->
        <div class="screen3"  ref="professional">
          <div class="title"> Oil & Gas Professional GPTs</div>
          <div class="light">
            The establishment of the Oil & Gas professional GPTs AI model is mainly based on the application of engineering technology companies such as Anton, and is open to industry companies for sharing.
            <span class="light2"><br>But due to insufficient generalization capabilities for other application scenarios，if you need more accurate professional applications, please<span   @click="clickConsult('Oil & Gas Professional GPTs')" style="color: #2F6EFF;cursor: pointer">&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro2">
            <!--        油藏-->
            <div class="grand2" @click="oilDesign()">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro1.jpg" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div style="margin-left: 15px;">
                  <div class="title1">Design-oriented GPTs</div>
                  <div class="title2">
                    Design-oriented GPTs taking into account various factors in the target region, a design plan is formulated to achieve rational development of Oil & Gas resources.
                  </div>
                </div>

              </div>
            </div>
            <!--        智能-->
            <div class="grand2" @click="oilTranslate()">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro7.jpg" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div style="margin-left: 15px;">
                  <div class="title1"> Explanatory GPTs</div>
                  <div class="title2">
                    Explanatory GPTs based on the explanation of Oil & Gas development objectives, it not only facilitates employees to grasp operational information in real-time but also provides robust support for the development process.
                  </div>
                </div>
              </div>
            </div>
            <!--        智能-->
            <div class="grand2" @click="oilTechnology()">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro2.jpg" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div style="margin-left: 15px;">
                  <div class="title1">Process-related GPTs</div>
                  <div class="title2">
                    Process-related GPTs,not only helps improve development efficiency but also reduces risk losses, ensuring stable development.
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!--    企业管理知识GPTs-->
        <div class="screenGeneral" style="background-color: white" ref="menageService">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto;text-align: center">
              <div class="title">&nbsp;Enterprise Management GPTs</div>
            </div>
          </div>
          <div class="light" >
            Enterprise management GPTs AI is based on specific enterprise environment training.
           <span class="light2"><br>But due to insufficient generalization capabilities, only case and demonstration services are provided for user reference. If you need further communication, please<span   @click="clickConsult('Enterprise Management GPTs')" style="color: #2F6EFF;cursor: pointer">&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro3">
            <!--        油藏-->

            <!--        人力-->
            <div class="grand2" @click="knowledge">
              <div class="back">
                <div class="color">
                  <div class="photo">     <img loading="lazy" src="@/assets/qiye.png"/></div>
                </div>
                <div class="title1">
                  Enterprise Management GPT<br> Development Services
                </div>
                <div class="title2">
                  Based on the internal management knowledge of client enterprises, we will build a knowledge-based large language model to enable employees to quickly and conveniently acquire knowledge.    </div>

              </div>
            </div>
            <!--        qhse-->
            <div class="grand2" @click="data">
              <div class="back">
                <div class="color">
                  <div class="photo"><img loading="lazy" src="@/assets/zhushihua.png"/></div>
                </div>
                <div class="title1">Enterprise Management Digital<br> Intelligence Overall Solution Services</div>
                <div class="title2">
                  Provide customers with consulting, design and planning of business-based management system digitalization architecture, and assist customers in planning and designing key projects.
                </div>

              </div>
            </div>

            <!--法律-->
            <div class="grand2" @click="wisdom">
              <div class="back">
                <div class="color">
                  <div class="photo"><img loading="lazy" src="@/assets/zhihui.png"/></div>
                </div>
                <div class="title1"> Data-driven Intelligent Decision-making Platform<br> Design And Construction Services
                </div>
                <div class="title2">
                  We assist client enterprises in leveraging data and AI model rules to achieve visual advance warnings, intelligent predictions, and support decision-making.</div>
              </div>

            </div>
            <!--        技术-->
            <div class="grand1" @click="design">
              <div class="back">
                <div class="color">
                  <div class="photo"><img loading="lazy" src="@/assets/yuangong.png"/></div>
                </div>
                <div class="title1">Smart Employee Community Design <br>And Construction Services</div>
                <div class="title2">
                  Help client enterprises build intelligent employee communities to assist employees in using various artificial intelligence office tools, thereby enhancing organizational efficiency.
                </div>
              </div>
            </div>
            <!--        计划-->
            <div class="grand1" @click="plat">
              <div class="back">
                <div class="color">
                  <div class="photo"><img loading="lazy" src="@/assets/dianshang.png"/></div>
                </div>
                <div class="title1">Smart E-commerce Platform Design, Construction And Operation Services</div>
                <div class="title2">
                  Assist client enterprises in building an intelligent, outward-facing e-commerce platform to support them in rapidly promoting their brands and products online.
                </div>

              </div>
            </div>
            <!--        财务-->
            <div class="grand1" @click="automation">
              <div class="back">
                <div class="color">
                  <div class="photo"><img loading="lazy" src="@/assets/liucheng.png"/></div>
                </div>
                <div class="title1">Management Process Automation Platform Design And Construction Services
                </div>
                <div class="title2">
                  Help client enterprises achieve uninterrupted and intelligent automation of processes such as identification, verification, and automated processing.

                </div>

              </div>
            </div>

          </div>


        </div>
        <!--通用类GPTs-->
        <div class="screenConsult1" ref="generalMove" style="background-color: transparent">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto;text-align: center">
              <div class="title">&nbsp;General GPTs</div>
            </div>
          </div>
          <div class="light">
            General GPTs Online Service，based on AI big model capabilities, provide employees with AI tools for learning improvement, creation generation, and workplace, assisting users to improve work efficiency.
            <span class="light2"><br>If you want to carry out customized training and development based on the open and general GPTs, please<span   @click="clickConsult('General GPTs')" style="color: #2F6EFF;cursor: pointer">&nbsp;Contact Us</span>.</span>

          </div>
          <div class="pro4">

            <!--油田-->
            <div class="grand2" @click="wen">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/wen.png"/>
                </div>
                <div class="title1">ERNIE Bot</div>
                <div class="title2">
                  ERNIE Bot is Baidu's new generation of knowledge-enhanced large language models that can dialog and interact with people, answer questions, assist in creation, and efficiently and conveniently help people access information, knowledge, and inspiration.
                </div>
              </div>
              <div class="consult">View Details<i class="el-icon-right"
                                                  style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
              </div>
            </div>
            <!--        油藏-->
            <div class="grand2" @click="dou">
              <div class="back">
                <div class="photo"><img src="@/assets/dou.png"/></div>
                <div class="title1">Grace</div>
                <div class="title2">
                  Grace is an AI tool developed by ByteDance based on the Skylark model, offering features such as a chatbot, a writing assistant, and an English learning assistant, which can help people access information by answering a variety of questions through dialog.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="tong">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/tong.png"/>
                </div>
                <div class="title1">Tongyi Qianwen</div>
                <div class="title2">
                  Tongyi Qianwen is a large language model independently developed by Aliyun, which can provide users with services and assistance in different fields and tasks through natural language understanding and semantic analysis on the basis of users' natural language input.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>
            <!--采油气-->
            <div class="grand2" @click="zhi">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/zhi.png"/>
                </div>
                <div class="title1">ChatGLM</div>
                <div class="title2">
                  The first generative AI assistant launched by Beijing Zhi Spectrum Huazhang Technology Co., Ltd, which is based on Zhi Spectrum AI's self-developed Chinese-English bilingual dialogue model ChatGLM2, pre-trained with trillions of characters of text and code, to provide users with intelligent services.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="xun">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/xun.png"/>
                </div>
                <div class="title1">IFLYTEK SPARK</div>
                <div class="title2">
                  The new generation of cognitive intelligence big model launched by KU Xunfei has cross-domain knowledge and language understanding ability, which can understand and perform tasks based on natural dialog way, providing language understanding, knowledge question and answer and other abilities.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>

            <!--采油气-->
            <div class="grand2" @click="teng">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/teng.png"/>
                </div>
                <div class="title1">Tencent Hunyuan</div>
                <div class="title2">
                  Tencent Hunyuan is a big language model developed by Tencent, with cross-domain knowledge and natural language understanding capabilities, to achieve based on human-computer natural language dialogue, understand user instructions and perform tasks, to help users realize the human access to information, knowledge and inspiration.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>

            <!--采油气-->
            <div class="grand2" @click="tian">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/tian.png"/>
                </div>
                <div class="title1">Tiangong AI</div>
                <div class="title2">
                  Tiangong AI is able to realize a variety of application scenarios such as personalized AI search, intelligent Q&A, chat interaction, etc., and has a rich knowledge base covering the fields of science, technology, culture, art, history and so on.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>
            <!--采油气-->
            <div class="grand2" @click="ge">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/ge.png"/>
                </div>
                <div class="title1">Wenxin Yige</div>
                <div class="title2">
                  Wenxin Yige is launched by Baidu relying on its Wenxin big model and flying paddle technology of text to generate pictures of A1 art and creative assistance platform, as the first echelon of the domestic AI picture generation tools and platforms.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>

            <!--采油气-->
            <div class="grand2" @click="wan">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/wan.png"/>
                </div>
                <div class="title1">WanCai</div>
                <div class="title2">
                  WanCai AI is a powerful collection of AI content creation tools. In addition to providing AI intelligent writing support, it also integrates powerful AI-generated content features such as AI face change, photo digital person creation and AI short video creation, which further expands the field of AI creation and gives you unlimited possibilities for your creation.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>

            <!--采油气-->
            <div class="grand2" @click="yi">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/yi.png"/>
                </div>
                <div class="title1">Yizhen TV</div>
                <div class="title2">
                  Yizhen TV is an intelligent AI content generation platform, which collects tools such as AI digital person, AI help writing, AI video, AI painting, etc. It supports multi-platform article one-key-to-video and digital person broadcasting video, and helps enterprises and self-media to create efficiently.
                </div>
                <div class="consult">View Details<i class="el-icon-right"
                                                    style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>
                </div>
              </div>
            </div>

          </div>

        </div>
<!--油气开发作业 -->
        <div class="screenConsult1" ref="moveProduct" >

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto;text-align: center">
              <div class="title" >&nbsp;GPT Application Services For Oil & Gas Development Operations</div>
            </div>
          </div>
          <div class="light" style="width: fit-content">
            GPT is used to assist Oil & Gas development field operations.
           <span class="light2"> <br>But due to limited generalization capabilities, if you need to improve the use effect during field operations and implement it quickly, please<span   @click="clickConsult('Oil & Gas Development Operations')" style="color: #2F6EFF;cursor: pointer">&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro1">
            <!--        油藏-->
            <div class="grand1" @click="ground">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro1.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">Intelligent Reservoir Geology Solutions</div>
              </div>
            </div>
            <!--        智能-->
            <div class="grand1" @click="hole">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro2.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">Intelligent Drilling Solutions</div>
              </div>
            </div>
            <!--        智能-->
            <div class="grand1" @click="frack">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro3.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">Intelligent Fracturing Solutions </div>
              </div>
            </div>
            <!--采油气-->
            <div class="grand1" @click="getOil">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro4.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">
                  Intelligent Recovery Solutions
                </div>
              </div>
            </div>
            <!--油田-->
            <!--            <div class="grand1" @click="menage">-->
            <!--              <div class="back">-->
            <!--                <div class="photo">-->
            <!--                  <img style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/pro5.png" />-->
            <!--                </div>-->
            <!--                <div class="title1"> 智能油田<br>解决方案</div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--地层-->
            <div class="grand1" @click="monitor">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro7.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1"> Formation Monitoring Solutions </div>
              </div>
            </div>
            <!--监测-->
            <div class="grand1" @click="equipment">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro6.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">
                  Equipment And Facility Monitoring Solutions
                </div>
              </div>
            </div>

          </div>

        </div>


        <!--    数智油田解决方案及GPT应用服务-->
        <div class="screenConsult1" style="background-color: transparent" ref="consultMove">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto;text-align: center">
              <div class="title">&nbsp;GPT Application Services For Smart Oilfield Solutions</div>
            </div>
          </div>
          <div class="light">
            Use large-model AI technology to build a full-scenario digital smart oilfield solution that combines geology and engineering to empower the comprehensive upgrade of digital smart oilfields.
            <span class="light2" >
            <br> If you want to display and exchange product solutions, please <span style="color: #2F6EFF;cursor: pointer" @click="clickConsult('Smart Oilfield Solutions')">&nbsp;Contact Us</span>.
          </span></div>
          <div class="pro2">
            <!--        智能-->
            <div class="grand1" @click="station" >
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home1.png"/>
                </div>
                <div style="width: 34vw">
                  <div class="title1" style="display: flex;align-items: center">Smart Station Solutions</div>
                  <div class="title2">
                    It builds digital intelligent twin station system to realize intelligent production management, human-machine intelligent interaction, ensure production safety, and improve control efficiency and operation quality.
                  </div>
                </div>
              </div>

            </div>
            <!--采油气-->
            <div class="grand1" @click="security">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home2.png"/>
                </div>
                <div style="width: 34vw">
                  <div class="title1" style="display: flex;align-items: center">Smart Security Solutions</div>
                  <div class="title2">
                    From the perspective of oilfield HSSE management, we design all-round management support, comprehensive safety control and comprehensive safety guarantee, covering safety education, risk control, video intelligent security, intelligent fire protection and so on.
                  </div>
                </div>
              </div>

            </div>
            <!--油田-->
            <div class="grand1" @click="productANxun">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home3.png"/>
                </div>
                <div style="width: 34vw">
                  <div class="title1">Oil & Gas Production Intelligent Control Solutions</div>
                  <div class="title2">
                    Relying on automated collection of well station data and video monitoring, the platform makes full use of cloud computing, big data and other new technologies to realize real-time sensing of Oil & Gas production dynamics, monitoring of the whole process, and precise and efficient operation command.
                  </div>
                </div>
              </div>

            </div>
            <!--        油藏-->
            <div class="grand1" @click="equipmentAnxun">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home4.png"/>
                </div>
                <div style="width: 32vw">
                  <div class="title1">Intelligent Monitoring Solutions of Equipment and Facilities</div>
                  <div class="title2">
                    Utilizing industrial IoT technology to monitor equipment data and ensure production safety. Early warning rules aid monitoring, diagnosis and overhaul to ensure equipment safety.
                  </div>
                </div>
              </div>

            </div>
            <!--        智能-->
            <div class="grand1" @click="technologyAnxun">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home5.png"/>
                </div>
                <div style="width: 34vw">
                  <div class="title1">Process Big-data Analysis System Solutions</div>
                  <div class="title2">
                    Real-time data and LIMS data as the core of the process management platform for the Oil & Gas field station production and construction of the enhancement of profitability, maximize the benefits and enhance the core competitiveness of enterprises.
                  </div>
                </div>
              </div>

            </div>
            <!--        智能-->
            <div class="grand1" @click="monitorAnxun">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home6.png"/>
                </div>
                <div style="width: 34vw">
                  <div class="title1">Petroleum Engineering Remote Monitoring and Command Platform</div>
                  <div class="title2">
                    Providing a new integrated solution for petroleum engineering platforms, realizing all-round data collection at the operation site and monitoring the status and operation of on-site equipments, including drilling, logging, directional, drilling and logging with drilling, fracking, well completion, and so on.
                  </div>
                </div>
              </div>

            </div>
            <!--采油气-->
            <div class="grand1" @click="networkAnxun">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home7.png"/>
                </div>
                <div style="width: 34vw">
                  <div class="title1">Smart Pipeline Network Solutions</div>
                  <div class="title2">
                    Build digital infrastructure to realize automatic control, data analysis and real-time monitoring. Create digital platforms and smart applications to manage the full life cycle of the pipeline network. Enhance mobility and security to improve management convenience and pipe network safety.
                  </div>
                </div>
              </div>

            </div>
            <!--油田-->
            <div class="grand1" @click="basic">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home8.png"/>
                </div>
                <div style="width: 34vw">
                  <div class="title1">Smart Oil and Gas Infrastructure Construction</div>
                  <div class="title2">
                    Facility construction covers machine rooms, data centers, etc. We provide our own software and hardware products, and focus on system integration and implementation, as well as maintenance and O&M services.
                  </div>
                </div>
              </div>

            </div>
            <!--        油藏-->
            <div class="grand1" @click="setting">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/home9.png"/>
                </div>
                <div style="width: 34vw">
                  <div class="title1">Comprehensive Digital and Intelligent Operations and Maintenance Services for the Oil and Gas Industry</div>

                  <div class="title2">
                    Professional service delivery team, based on the characteristics of the Oil & Gas industry, management needs, to provide personalized, customized, accompanied by the digital operation and maintenance services.
                  </div>
                </div>
              </div>

            </div>


          </div>

        </div>

        <!--多媒体-->
        <div  class="screen4" ref="mediaMove">
          <div class="title">Intelligent Multimedia Services</div>
          <div class="light" style="width: fit-content">
            Help corporate customers provide Intelligent multimedia services for the Oil & Gas industry, making the Oil & Gas world more lively and stylish.<span class="light2">

          <br> If you want to display and exchange product solutions, please<span style="color: #2F6EFF;cursor: pointer"
                                                                 @click="clickConsult('Intelligent Multimedia Services')">&nbsp;Contact Us</span>.

            </span>
          </div>
          <div class="pro2">
            <!--        油藏-->
            <div class="grand2" @click="meitiProduct()">
              <div class="back">
                <div class="photo"  style="height: 32vh">
                  <img loading="lazy" src="@/assets/meiti1.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div style="margin-left: 15px;">
                  <div class="title1">Promotional Videos</div>
                  <div class="title2">Dig deeper into your brand story
                  </div>
                </div>

              </div>
            </div>
            <!--        智能-->
            <div class="grand2" @click="meiti3D()">
              <div class="back">
                <div class="photo" style="height: 32vh">
                  <img loading="lazy" src="@/assets/meiti2.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div style="margin-left: 15px;">
                  <div class="title1">3D Animation</div>
                  <div class="title2">
                    Let your technology and products be presented in three dimensions</div>
                </div>
              </div>
            </div>
            <!--        智能-->
            <div class="grand2" @click="meitiDesign()">
              <div class="back">
                <div class="photo" style="height: 32vh">
                  <img loading="lazy" src="@/assets/meiti3.jpg"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div style="margin-left: 15px;">
                  <div class="title1">Graphic Design</div>
                  <div class="title2">
                    Brightly color the Oil & Gas industry with aesthetically pleasing visual designs</div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <!--人工智能转型服务-->
        <div class=" screen8" ref="aiPeixun">
          <div class="title">AI Universal Training And Transformation Consulting Services</div>
          <div class="light" style="width: fit-content">
            Help customers achieve artificial transformation planning, project design, and expert consulting services.
            <span class="light2"><br>If you have training or consultation needs, please<span   @click="clickConsult('AI Training And Transformation')" style="color: #2F6EFF;cursor: pointer">&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro2">

            <!--        智能-->
            <div ref="station" class="grand3" @click="traningAi">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/puji2.png" style="border-top-left-radius: 6px;border-bottom-left-radius: 6px"/>
                </div>
                <div style="width: 100%;background-color: white;border-top-right-radius: 9px;border-bottom-right-radius: 9px">
                  <div class="title1">
                    AI Application Training For All Employees</div>
                  <div class="title2">
                    Help all employees of client companies to comprehensively improve their work skills using AI technology and tools.</div>
                </div>
              </div>

            </div>
            <!--采油气-->
            <div class="grand3" @click="consult">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/puji1.png" style="border-top-left-radius: 6px;border-bottom-left-radius: 6px"/>
                </div>
                <div style="width: 100%;background-color: white;border-top-right-radius: 9px;border-bottom-right-radius: 9px">
                  <div class="title1">Enterprise AI Transformation Consulting</div>
                  <div class="title2">Help customer companies plan and formulate overall AI transformation, and sort out the entire artificial intelligence transformation scenario, thereby supporting the full implementation of customers' AI transformation.</div>
                </div>
              </div>

            </div>


          </div>
         </div>


        <!--油气企业自建GPT云平台服务-->
        <div class=" screen10" ref="companyZijian">
          <div class="title">Oil & Gas Enterprise Self-built GPT Cloud Platform Services</div>
          <div class="light">
            Help enterprise customers build an AI cloud platform in a private environment, relying on the platform to accumulate model results, computing resources, and data for each application scenario, to maximize the sharing of models and make full use of computing resources.
            <span class="light2"> <br>If you want to build your own GPT cloud platform in a private environment, please<span @click="clickConsult('Enterprise Self-built GPT')" style="color: #2F6EFF;cursor: pointer">&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro2">

            <!--        智能-->
            <div ref="station" class="grand3" @click="constructPlat">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/zijian1.png" style="border-top-left-radius: 6px;border-top-right-radius: 6px"/>
                </div>
                <div>
                  <div class="title1">
                    Oil & Gas Enterprise Large Model Cloud Platform Planning And Construction Services</div>
                </div>
              </div>

            </div>
            <!--采油气-->
            <div class="grand2" @click="business">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/zijian2.png" style="border-top-left-radius: 6px;border-top-right-radius: 6px"/>
                </div>
                <div>
                  <div class="title1" style="display:flex;align-items: center;justify-content: center">Business GPTs Project Services</div>
                </div>
              </div>

            </div>
            <!--油田-->
            <div class="grand1" @click="company">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/zijian3.png" style="border-top-left-radius: 6px;border-top-right-radius: 6px"/>
                </div>
                <div>
                  <div class="title1">Oil & Gas Company Data (Knowledge) Governance Services</div>
                </div>
              </div>

            </div>
            <!--        油藏-->
            <div class="grand3" @click="computingPower">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/zijian4.png" style="border-top-left-radius: 6px;border-top-right-radius: 6px"/>
                </div>
                <div>
                  <div class="title1">Basic Computing Power Optimization Services</div>
                </div>
              </div>

            </div>


          </div>
           </div>

        <div class="  screen6">

          <!--      三大优势-->
          <div class="pro3">
            <div class="title">Anvision Services</div>
            <div class="title2">Oil & Gas Generic AI Platform is built and operated by Anvision, adhering to the principle of serving all parties in the Oil & Gas industry and making Oil & Gas counting intelligence simple and easy. As an industry-leading oilfield digital intelligence technology service company under the Anton Group, Anvision provides full-stack solutions, products and services for the Oil & Gas industry by focusing on customers, perfectly combining digital intelligence technology with Oil & Gas technology, and providing on-site concomitant services in the Oil & Gas industry in the simplest way, at the lowest cost, in the fastest cycle, and the easiest to implement.</div>


            <div class="companyIntroduce">

              <!--        智能-->
              <div class="grand2">
                <div class="back">
                  <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%)">
                    <img loading="lazy" src="@/assets/shuzhiback.png"/>
                  </div>
                  <div class="title">
                    <div class="title1">
                      <img loading="lazy" src="@/assets/anxunCompang.png"/></div>
                    <div class="title2">
                      Anvision is based on various application scenarios in Oil & Gas fields, and provides professional accompanying services with the most reliable project implementation, fastest project cycle, and lowest cost to meet the rigid digital intelligence needs of Oil & Gas fields. Services include comprehensive solutions for smart oil fields and full-scenario digital intelligence services for Oil & Gas fields.</div>
                    <div class="title3">
                      <img loading="lazy" src="@/assets/anxunSlogn.png"/></div>
                  </div>
                </div>

              </div>
              <div class="grand3">
                <div class="back">
                  <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);width: 100%">

                    <img loading="lazy" src="@/assets/anxun.png"/>
                  </div>
                  <div class="title">
                    <div class="title1">
                      <img loading="lazy" src="@/assets/oilCompany.png"/></div>
                    <div class="title2">
                      Anvision Oil & Gas Intelligence Company focuses on providing AI technology solutions for the oil industry, and is committed to deeply integrating AI technology into the oil business. Our professional team has rich industry experience and advanced AI technology, which can provide efficient and accurate solutions for oil companies, helping them achieve digital transformation and intelligent upgrading.
                      </div>
                    <div class="title3">
                      <img loading="lazy" src="@/assets/oilSlogin.png"/></div>
                  </div>
                </div>

              </div>

              <div class="grand4" >
                <div class="back" >
                  <div class="photoHidden" style="top:0;opacity: 0.1;left:50%;transform:translateX(-50%);height: 100%">
                    <img loading="lazy" src="@/assets/mediaCompany.png"/>
                  </div>
                  <div class="title">
                    <div class="title1">
                      <img loading="lazy" src="@/assets/mediaCom.png"/></div>
                    <div class="title2">
                      Oil Mates Multimedia Services are committed to serving the multimedia needs of the Oil & Gas industry and making the Oil & Gas world more vivid and stylish. Provide scientific visualization, marketing promotion and brand optimization services to meet customers' multimedia needs in technology promotion, brand promotion, training, competitions and scientific research reports.
                    </div>
                    <div class="title3">
                      <img loading="lazy" src="@/assets/mediaSlogin.png"/></div>
                  </div>
                </div>

              </div>


            </div>



            <!--            <div class="banner">-->
<!--              <div class="back1">-->
<!--                <div class="background"></div>-->
<!--                <div class="backTitle1">Intelligent Full Scene Coverage</div>-->
<!--                <div class="backTitle2">-->
<!--                  Covers the entire process of geological exploration, drilling, completion and other intelligent scenarios.-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="back4">-->
<!--                <div class="background"></div>-->
<!--                <div class="backTitle1">Oilfield Site Verification</div>-->
<!--                <div class="backTitle2">-->
<!--                 It has been the first to be put into use in multiple projects delivered by Anton, achieving cost reduction and efficiency improvement.-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="back3">-->
<!--                <div class="background"></div>-->
<!--                <div class="backTitle1">Oil & Gas Information Accumulation</div>-->
<!--                <div class="backTitle2">-->
<!--                  Using Anton's vast oilfield engineering knowledge, intelligent models undergo training and continuous optimization.</div>-->
<!--              </div>-->
<!--              <div class="back2">-->
<!--                <div class="background"></div>-->
<!--                <div class="backTitle1">Technology Ecological Partner</div>-->
<!--                <div class="backTitle2">-->
<!--                  Accumulate AI partner resources in data governance, large models, computing, and app development.-->

<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>


        </div>

        <!--底部-->
        <div class="screen7">
          <bottomTip></bottomTip>
        </div>

        <consultTip
            :thisTitle="title"
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>

        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
    <div v-else>
      <div id="totalModel" ref="totalModel" class="totalModel">
        <div class="screen1">
          <headOther @showDialog="showPartner"></headOther>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
        </div>
        <div class="up" v-if="scrollTrue===false" @click="uptop"></div>
        <div class="feedback"  @click="feedback">Feed-<br>back</div>

        <!--    油气资讯GPTs-->
        <div ref="zixun" class="screen3">

          <div class="title">&nbsp;Oil & Gas Information GPTs</div>
          <div class="light">
            We provide users with free online Oil & Gas information services based on AI large model technology.
            <span class="light2"> <br>Since the AI model cannot be completely accurate and is limited by computing power，if you want to get better results, please<span style="color: #2F6EFF;cursor: pointer" @click="clickConsult('Oil & Gas Information GPTs')">&nbsp;Contact Us</span>.</span>
          </div>

          <div class="pro2">
            <!--采油气-->
            <div class="grand1" @click="info1">
              <div class="back">
                <div class="photo">
                  <div class="img">
                    <img src="@/assets/news3.png"/>
                  </div>
                </div>
                <div style="margin-left: 15px;height: 115px">
                  <div class="title1">Oil & Gas Information
                  </div>
                  <div class="title2">
                    AI large model oil & gas information platform.
                  </div>
                </div>
              </div>

            </div>
            <!--        智能-->
            <div class="grand1" @click="expo1">
              <div class="back">
                <div class="photo">
                  <div class="img">
                    <img src="@/assets/news1.png" style="border-radius: 6px;height: 90%"/>
                  </div>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1">Oil & Gas Exposition</div>
                  <div class="title2">
                    Provides online promotional and exhibition services for global Oil & Gas enterprises.
                  </div>
                </div>
              </div>

            </div>

            <!--油田-->
            <div class="grand1" @click="job1">
              <div class="back">
                <div class="photo">
                  <div class="img">
                    <img src="@/assets/news2.png" style="height: 70%"/>
                  </div>
                </div>

                <div style="margin-left: 15px">
                  <div class="title1">Oil & Gas JobAI</div>
                  <div class="title2">
                    Global Oil & Gas Industry Professional Talent Job and Recruitment Platform.
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <!--    油气专业类GPTs-->
        <div class="screen3" style="background-color: transparent"   ref="professional1">

          <div class="title">Oil & Gas Professional GPTs </div>
          <div class="light">

            The establishment of the Oil & Gas professional GPTs AI model is mainly based on the application of engineering technology companies such as Anton, and is open to industry companies for sharing.
            <span class="light2"><br>But due to insufficient generalization capabilities for other application scenarios，if you need more accurate professional applications, please<span style="color: #2F6EFF;cursor: pointer" @click="clickConsult('Oil & Gas Professional GPTs ')"><br>&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro1">
            <!-- 设计类-->
            <div class="grand1" @click="oilgpt1()">
              <div class="back">
                <div class="photo"></div>
              </div>
              <div class="back1" style="">
                <div class="title1" style="text-align: center;">Design-oriented GPTs</div>
                <div class="title2" style="">
                  Design-oriented GPTs taking into account various factors in the target region, a design plan is formulated to achieve rational development of Oil & Gas resources.
                </div>
              </div>
            </div>
            <!-- 解释类-->
            <div class="grand1" @click="oilgpt21()">
              <div class="back">
                <div class="photo">
                  <img style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/pro7.jpg"/>
                </div>

              </div>
              <div class="back1" style="">
                <div class="title1" style="text-align: center;">Explanatory GPTs</div>
                <div class="title2">
                  Explanatory GPTs based on the explanation of Oil & Gas development objectives, it not only facilitates employees to grasp operational information in real-time but also provides robust support for the development process.
                </div>
              </div>
            </div>
            <!--  工艺类-->
            <div class="grand1" @click="oilgpt31()">
              <div class="back">
                <div class="photo">
                  <img style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/pro2.jpg"/>
                </div>
              </div>
              <div class="back1" style="">
                <div class="title1" style="text-align: center;">Process-related GPTs</div>
                <div class="title2">
                  Process-related GPTs,not only helps improve development efficiency but also reduces risk losses, ensuring stable development.
                </div>
              </div>
            </div>

          </div>

        </div>

        <!--    企业管理知识GPTs-->
        <div class="screenConsult1" style="background-color: white"  ref="menageServices">
          <div class="title">Enterprise Management GPTs</div>
          <div class="light">
            Enterprise management GPTs AI is based on specific enterprise environment training.
            <span class="light2"><br> But due to insufficient generalization capabilities, only case and demonstration services are provided for user reference. If you need further communication, please <span style="color: #2F6EFF;cursor: pointer" @click="clickConsult('Enterprise Management GPTs')">&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro4">

            <!--人力-->
            <div class="grand1" @click="knowledge1">
              <div class="back">

                <div class="photo"><img loading="lazy" src="@/assets/qiye1.png" /></div>
                <div class="title1">
                  Enterprise Management GPT Development Services
                </div>

              </div>
            </div>
            <!--        QHSe-->
            <div class="grand1" @click="data1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/zhushihua1.png"/>
                </div>
                <div class="title1">Enterprise Management Digital Intelligence Overall Solution Services</div>

              </div>
            </div>

            <!--法律-->
            <div class="grand1" @click="wisdom1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/zhihui1.png" />
                </div>
                <div class="title1"> Data-driven Intelligent Decision-making Platform Design And Construction Services
                </div>

              </div>
            </div>

            <!--        技术-->
            <div class="grand1" @click="design1">
              <div class="back">
                <div class="photo"><img loading="lazy" src="@/assets/yuangong1.png"/></div>
                <div class="title1">Smart Employee Community Design And Construction Services</div>

              </div>
            </div>
            <!--        计划-->
            <div class="grand1" @click="plat1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/dianshang1.png"/>
                </div>
                <div class="title1">Smart E-commerce Platform Design, Construction And Operation Services</div>

              </div>
            </div>

            <!--        财务-->
            <div class="grand1" @click="automation1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/liucheng1.png" />
                </div>
                <div class="title1">Management Process Automation Platform Design And Construction Services
                </div>
              </div>
            </div>

          </div>



        </div>
        <!--    通用性GPTs-->
        <div ref="general" class="screenGeneral">
          <div class="title">General GPTs</div>
          <div class="light">
            General GPTs Online Service，based on AI big model capabilities, provide employees with AI tools for learning improvement, creation generation, and workplace, assisting users to improve work efficiency.
            <span class="light2"> <br>If you want to carry out customized training and development based on the open and general GPTs, please <span style="color: #2F6EFF;cursor: pointer" @click="clickConsult('General GPTs')">&nbsp;Contact Us</span>.</span>
          </div>
          <div class="pro1">
            <!--油田-->
            <div class="grand2" @click="wen1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/wen.png"/>
                </div>
                <div>
                  <div class="title1">ERNIE Bot</div>

                </div>

              </div>
            </div>
            <!--        油藏-->
            <div class="grand2" @click="dou1">
              <div class="back">
                <div class="photo"><img src="@/assets/dou.png"/></div>
                <div>
                  <div class="title1">Grace</div>

                </div>
              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="tong1">
              <div class="back">
                <div class="photo">
                  <img style="height: 85%;margin-top: 7.5%" src="@/assets/tong.png"/>
                </div>
                <div>
                  <div class="title1">Tongyi Qianwen</div>

                </div>
              </div>
            </div>
            <!--采油气-->
            <div class="grand2" @click="zhi1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/zhi.png" style="height: 75%;margin-top: 10%"/>
                </div>
                <div>
                  <div class="title1">ChatGLM</div>

                </div>
              </div>
            </div>

            <!--        智能-->
            <div class="grand2" @click="xun1">
              <div class="back">
                <div class="photo">
                  <img  src="@/assets/xun.png" style="height:85%;margin-top: 5%"/>
                </div>
                <div>
                  <div class="title1">IFLYTEK SPARK</div>

                </div>
              </div>
            </div>

            <!--采油气-->
            <div class="grand2" @click="teng1">
              <div class="back">
                <div class="photo">
                  <img style="height: 90%;margin-top: 5%" src="@/assets/teng.png"/>
                </div>
                <div>
                  <div class="title1">Tencent Hunyuan</div>

                </div>
              </div>
            </div>

            <!--采油气-->
            <div class="grand2" @click="tian1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/tian.png"/>
                </div>
                <div>
                  <div class="title1">Tiangong AI</div>

                </div>
              </div>
            </div>
            <!--采油气-->
            <div class="grand2" @click="ge1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/wen.png"/>
                </div>
                <div>
                  <div class="title1">Wenxin Yige</div>

                </div>
              </div>
            </div>

            <!--采油气-->
            <div class="grand2" @click="wan1">
              <div class="back">
                <div class="photo">
                  <img style="height: 90%;margin-top: 5%" src="@/assets/wan.png"/>
                </div>
                <div>
                  <div class="title1">WanCai</div>

                </div>
              </div>
            </div>

            <!--            &lt;!&ndash;采油气&ndash;&gt;-->
            <!--            <div class="grand2" @click="yi1">-->
            <!--              <div class="back">-->
            <!--                <div class="photo">-->
            <!--                  <img  style="height: 85%;margin-top: 10%" src="@/assets/yi.png"/>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <div class="title1">Yizhen TV</div>-->
            <!--                  <div class="title2">-->
            <!--                    Yizhen TV is an intelligent AI content generation platform, which collects tools such as AI digital person, AI help writing, AI video, AI painting, etc. It supports multi-platform article one-key-to-video and digital person broadcasting video, and helps enterprises and self-media to create efficiently.-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

          </div>


        </div>


        <!--    油气开发作业GPT应用项目服务-->
        <div class="screenConsult">

          <div style="display: flex;margin:0 auto;"   ref="upload">
            <div style="display: flex;margin:0 auto;text-align: center">

              <div class="title"> GPT Application Services For Oil & Gas Development Operations</div>
            </div>
          </div>

          <div class="light">
            GPT is used to assist Oil & Gas development field operations.
            <span class="light2"> <br>But due to limited generalization capabilities, if you need to improve the use effect during field operations and implement it quickly, please <span style="color: #2F6EFF;cursor: pointer" @click="clickConsult('Oil & Gas Development Operations')">&nbsp;Contact Us</span>.</span>

          </div>
          <div class="pro1">
            <!--        油藏-->
            <div class="grand1" @click="ground1">
              <div class="back">
                <div class="photo"></div>
                <div class="title1">Intelligent Reservoir Geology Solutions</div>
              </div>
            </div>
            <!--        智能-->
            <div class="grand1" @click="hole1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro2.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">Intelligent Drilling Solutions </div>
              </div>
            </div>
            <!--        智能-->
            <div class="grand1" @click="frack1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro3.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">Intelligent Fracturing Solutions</div>
              </div>
            </div>
            <!--采油气-->
            <div class="grand1" @click="getOil1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro4.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">
                  Intelligent Recovery Solutions
                </div>
              </div>
            </div>
            <!--油田-->
            <!-- <div class="grand1" @click="menage">
              <div class="back">
                <div class="photo">
                  <img style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/pro5.png" />
                </div>
                <div class="title1"> 智能油田解决方案</div>
              </div>
            </div> -->
            <!--地层-->
            <div class="grand1" @click="monitor1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro7.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1"> Formation Monitoring Solutions </div>
              </div>
            </div>
            <!--监测-->
            <div class="grand1" @click="equipment1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/pro6.png" style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
                <div class="title1">
                  Equipment And Facility Monitoring Solutions
                </div>
              </div>
            </div>

          </div>



        </div>



        <!--    数智油田-->
        <div class="screenConsult"  ref="station" style="background-color: transparent">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto;text-align: center">
              <div class="title" >GPT Application Services For Smart Oilfield Solutions</div>
            </div>
          </div>

          <div class="light">Use large-model AI technology to build a full-scenario digital smart oilfield solution that combines geology and engineering to empower the comprehensive upgrade of digital smart oilfields. <span class="light2" >

       <br> If you want to display and exchange product solutions, please<span style="color: #2F6EFF;cursor: pointer"
                                                                   @click="clickConsult('Smart Oilfield Solutions')">&nbsp;Contact Us</span>.


          </span></div>
          <div class="pro2">
            <!--        智能-->
            <div class="grand1" @click="station1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home1.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1" style="display: flex;align-items: center;text-align: left">Smart Station Solutions</div>
                  <div class="title2">
                    It builds digital intelligent twin station system to realize intelligent production management, human-machine intelligent interaction, ensure production safety, and improve control efficiency and operation quality.
                  </div>
                </div>
              </div>

            </div>
            <!--采油气-->
            <div class="grand1" @click="security1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home2.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1" style="display: flex;align-items: center;text-align: left;">Smart Security Solutions</div>
                  <div class="title2">
                    From the perspective of oilfield HSSE management, we design all-round management support, comprehensive safety control and comprehensive safety guarantee, covering safety education, risk control, video intelligent security, intelligent fire protection and so on.
                  </div>
                </div>
              </div>

            </div>
            <!--油田-->
            <div class="grand1" @click="productANxun1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home3.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1">Oil & Gas Production Intelligent Control Solutions</div>
                  <div class="title2">
                    Relying on automated collection of well station data and video monitoring, the platform makes full use of cloud computing, big data and other new technologies to realize real-time sensing of Oil & Gas production dynamics, monitoring of the whole process, and precise and efficient operation command.
                  </div>
                </div>
              </div>

            </div>
            <!--        油藏-->
            <div class="grand1" @click="equipmentAnxun1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home4.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1">Intelligent Monitoring Solutions of Equipment and Facilities</div>
                  <div class="title2">
                    Utilizing industrial IoT technology to monitor equipment data and ensure production safety. Early warning rules aid monitoring, diagnosis and overhaul to ensure equipment safety.
                  </div>
                </div>
              </div>

            </div>
            <!--        智能-->
            <div class="grand1" @click="technologyAnxun1" ref="technology">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home5.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1">Process Big-data Analysis System Solutions</div>
                  <div class="title2">
                    Real-time data and LIMS data as the core of the process management platform for the Oil & Gas field station production and construction of the enhancement of profitability, maximize the benefits and enhance the core competitiveness of enterprises.
                  </div>
                </div>
              </div>

            </div>
            <!--        智能-->
            <div class="grand1" @click="monitorAnxun1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home6.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1">Petroleum Engineering Remote Monitoring & Command Solution IntroductionPetroleum Engineering Remote Monitoring & Command Solution Introduction </div>
                  <div class="title2">
                    Providing a new integrated solution for petroleum engineering platforms, realizing all-round data collection at the operation site and monitoring the status and operation of on-site equipments, including drilling, logging, directional, drilling and logging with drilling, fracking, well completion, and so on.
                  </div>
                </div>
              </div>

            </div>
            <!--采油气-->
            <div ref="networkAnxun" class="grand1" @click="networkAnxun1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home7.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1" style="display: flex;align-items: center;text-align: left">Smart Pipe Network Solution</div>
                  <div class="title2">
                    Build digital infrastructure to realize automatic control, data analysis and real-time monitoring. Create digital platforms and smart applications to manage the full life cycle of the pipeline network. Enhance mobility and security to improve management convenience and pipe network safety.
                  </div>
                </div>
              </div>

            </div>
            <!--油田-->
            <div ref="elementbasic" class="grand1" @click="basic1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home8.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1">Smart Oil and Gas Infrastructure Construction</div>
                  <div class="title2">
                    Facility construction covers machine rooms, data centers, etc. We provide our own software and hardware products, and focus on system integration and implementation, as well as maintenance and O&M services.
                  </div>
                </div>
              </div>

            </div>
            <!--        油藏-->
            <div class="grand1" @click="setting1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/home9.png" style="border-radius: 6px"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="title1">Comprehensive Digital and Intelligent Operations and Maintenance Services for the Oil and Gas Industry</div>

                  <div class="title2">
                    Professional service delivery team, based on the characteristics of the Oil & Gas industry, management needs, to provide personalized, customized, accompanied by the digital operation and maintenance services.
                  </div>
                </div>
              </div>

            </div>


          </div>



        </div>
        <!--    多媒体服务-->
        <div  class="screen3" ref="media" style="background-color: white">

          <div class="title">Intelligent Multimedia Services</div>
          <div class="light">
            Help corporate customers provide multimedia services for the Oil & Gas industry, making the Oil & Gas world more lively and stylish.
            <span class="light2" >

         <br> If you want to display and exchange product solutions, please
         <span style="color: #2F6EFF;cursor: pointer"
               @click="clickConsult('Intelligent Multimedia Services')">&nbsp;Contact Us</span>.


          </span>
          </div>
          <div class="pro11">
            <!-- 设计类-->
            <div class="grand1" @click="meitiProduct1()">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/meiti11.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
              </div>
              <div class="back1" style="">
                <div class="title1" style="text-align: center;">Promotional Videos</div>
                <div class="title2" style="text-align: center;">
                  Dig deeper into your brand story
                </div>
              </div>
            </div>
            <!-- 解释类-->
            <div class="grand1" @click="meiti3D1()">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/meiti22.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>

              </div>
              <div class="back1" style="">
                <div class="title1" style="text-align: center;">3D Animation</div>
                <div class="title2" style="text-align: center;">
                  Let your technology and products be presented in three dimensions
                </div>
              </div>
            </div>
            <!--  工艺类-->
            <div class="grand1" @click="meitiDesign1()">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/meiti33.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
              </div>
              <div class="back1" style="">
                <div class="title1" style="text-align: center;">Graphic Design</div>
                <div class="title2" style="text-align: center;">Brightly color the Oil & Gas industry with aesthetically pleasing visual designs
                </div>
              </div>
            </div>

          </div>




        </div>


        <!--    人工智能全员普及培训人工智能全员普及培训-->
        <div class="screen3" ref="traningConsult" style="background-color: transparent">

          <div class="title">AI Universal Training And Transformation Consulting Services</div>
          <div class="light">
            Help customers achieve artificial transformation planning, project design, and expert consulting services.
            <span class="light2"><br>If you have training or consultation needs, please<span style="color: #2F6EFF;cursor: pointer" @click="clickConsult('AI Training And Transformation')">&nbsp;Contact Us</span>.</span>

          </div>
          <div class="pro1">
            <!-- 设计类-->
            <div class="grand1" @click="traningAi1()">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/puji22.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>
              </div>
              <div class="back1" style="">
                <div class="title1" style="text-align: center;">AI Application Training For All Employees</div>
                <div class="title2" style="">
                  Help all employees of client companies to comprehensively improve their work skills using AI technology and tools.
                </div>
              </div>
            </div>
            <!-- 解释类-->
            <div class="grand1" @click="consult1()">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" src="@/assets/puji11.png"
                       style="border-top-right-radius: 9px;border-top-left-radius: 9px"/>
                </div>

              </div>
              <div class="back1" style="">
                <div class="title1" style="text-align: center;">Enterprise AI Transformation Consulting</div>
                <div class="title2">
                  Help customer companies plan and formulate overall AI transformation, and sort out the entire artificial intelligence transformation scenario, thereby supporting the full implementation of customers' AI transformation. </div>
              </div>
            </div>

          </div>

        </div>



        <!--    油气企业自建GPT云平台服务-->
        <div  class="screenConsult1" style="background-color: white" ref="companyConduct">
          <div class="title">Oil & Gas Enterprise Self-built GPT Cloud Platform Services</div>
          <div class="light">
            Help enterprise customers build AI cloud platforms in private environments, relying on the platform to accumulate model results, computing resources, and data for each application scenario, to maximize the sharing of models and make full use of computing resources.
            <span class="light2"><br>If you want to build your own GPT cloud platform in a private environment, please<span style="color: #2F6EFF;cursor: pointer" @click="clickConsult('Enterprise Self-built GPT')">&nbsp;Contact Us</span>.</span>

          </div>

          <div class="pro3">

            <!--人力-->
            <div class="grand1" @click="constructPlat1">
              <div class="back">

                <div class="photo"><img style="border-top-left-radius: 9px;border-top-right-radius: 9px" loading="lazy" src="@/assets/zijian1.png" /></div>
                <div class="title1">
                  Oil & Gas Enterprise Large Model Cloud Platform Planning And Construction Services
                </div>

              </div>
            </div>
            <!--        QHSe-->
            <div class="grand1" @click="business1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/zijian2.png"/>
                </div>
                <div class="title1">Business GPTs Project Services</div>
              </div>
            </div>

            <!--法律-->
            <div class="grand1" @click="company1">
              <div class="back">
                <div class="photo">
                  <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/zijian3.png" />
                </div>
                <div class="title1"> Oil & Gas Company Data (Knowledge) Governance Services
                </div>

              </div>
            </div>

            <!--        技术-->
            <div class="grand1" @click="computingPower1">
              <div class="back">
                <div class="photo"><img style="border-top-left-radius: 9px;border-top-right-radius: 9px" loading="lazy" src="@/assets/zijian4.png"/></div>
                <div class="title1">Basic Computing Power Optimization Services</div>

              </div>
            </div>

          </div>

        </div>



        <div class="  screen6">

          <!--      三大优势-->
          <div class="pro3">
            <div class="title">Anvision Services</div>
            <div class="title2">Oil & Gas Generic AI Platform is built and operated by Anvision, adhering to the principle of serving all parties in the Oil & Gas industry and making Oil & Gas counting intelligence simple and easy. As an industry-leading oilfield digital intelligence technology service company under the Anton Group, Anvision provides full-stack solutions, products and services for the Oil & Gas industry by focusing on customers, perfectly combining digital intelligence technology with Oil & Gas technology, and providing on-site concomitant services in the Oil & Gas industry in the simplest way, at the lowest cost, in the fastest cycle, and the easiest to implement.</div>


            <div class="companyIntroduce">

              <!--        智能-->
              <div class="grand2">
                <div class="back">
                  <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%)">
                    <img loading="lazy" src="@/assets/shuzhiback.png"/>
                  </div>
                  <div class="title">
                    <div class="title1">
                      <img loading="lazy" src="@/assets/anxunCompang.png"/></div>
                    <div class="title2">
                      Anvision is based on various application scenarios in Oil & Gas fields, and provides professional accompanying services with the most reliable project implementation, fastest project cycle, and lowest cost to meet the rigid digital intelligence needs of Oil & Gas fields. Services include comprehensive solutions for smart oil fields and full-scenario digital intelligence services for Oil & Gas fields.</div>
                    <div class="title3">
                      <img loading="lazy" src="@/assets/anxunSlogn.png"/></div>
                  </div>
                </div>

              </div>
              <div class="grand3">
                <div class="back">
                  <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);height: 25%">

                    <img loading="lazy" src="@/assets/anxun.png"/>
                  </div>
                  <div class="title">
                    <div class="title1">
                      <img loading="lazy" src="@/assets/oilCompany.png"/></div>
                    <div class="title2">
                      Anvision Oil & Gas Intelligence Company focuses on providing AI technology solutions for the oil industry, and is committed to deeply integrating AI technology into the oil business. Our professional team has rich industry experience and advanced AI technology, which can provide efficient and accurate solutions for oil companies, helping them achieve digital transformation and intelligent upgrading.   </div>
                    <div class="title3">
                      <img loading="lazy" src="@/assets/oilSlogin.png"/></div>
                  </div>
                </div>

              </div>

              <div class="grand4" >
                <div class="back" >
                  <div class="photoHidden" style="top:0;opacity: 0.1;left:50%;transform:translateX(-50%);height: 100%">
                    <img loading="lazy" src="@/assets/mediaCompany.png"/>
                  </div>
                  <div class="title">
                    <div class="title1">
                      <img loading="lazy" src="@/assets/mediaCom.png"/></div>
                    <div class="title2">
                      Oil Mates Multimedia Services are committed to serving the multimedia needs of the Oil & Gas industry and making the Oil & Gas world more vivid and stylish. Provide scientific visualization, marketing promotion and brand optimization services to meet customers' multimedia needs in technology promotion, brand promotion, training, competitions and scientific research reports.

                    </div>
                    <div class="title3">
                      <img loading="lazy" src="@/assets/mediaSlogin.png"/></div>
                  </div>
                </div>

              </div>


            </div>

            <!--            <div class="banner">-->
<!--              <div class="back1">-->
<!--                <div class="backTitle1"> Intelligent Full Scene Coverage</div>-->
<!--                <div class="span"></div>-->
<!--                <div class="backTitle2">-->
<!--                  Covers the entire process of geological exploration, drilling, completion and other intelligent scenarios.-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="back1">-->
<!--                <div class="backTitle1">Oilfield Site Verification</div>-->
<!--                <div class="span"></div>-->
<!--                <div class="backTitle2">-->
<!--                 It has been the first to be put into use in multiple projects delivered by Anton, achieving cost reduction and efficiency improvement.-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="back1">-->
<!--                <div class="backTitle1">Oil & Gas Information Accumulation</div>-->
<!--                <div class="span"></div>-->
<!--                <div class="backTitle2">-->
<!--                  Using Anton's vast oilfield engineering knowledge, intelligent models undergo training and continuous optimization. </div>-->
<!--              </div>-->
<!--              <div class="back1">-->
<!--                <div class="backTitle1">Technology Ecological Partner</div>-->
<!--                <div class="span"></div>-->
<!--                <div class="backTitle2">-->
<!--                  Accumulate AI partner resources in data governance, large models, computing, and app development.-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>


        </div>

        <!--底部-->
        <div class="screen7" ref="bottom">
          <bottomTip></bottomTip>
        </div>

        <consultTip
            :thisTitle="title"
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
  </div>
</template>
<script>
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import headtip from "@/views/headTip.vue";
import headOther from "@/views/headOther.vue";
import bottomTip from "@/views/bottomTip.vue";
import technology from "@/views/Anxun/technology.vue";
import network from "@/views/Anxun/network.vue";

export default {
  name: "Home",
  components: {
    bottomTip,
    PartnerTip,
    consultTip,
    headtip,
    headOther
  },

  data() {
    return {

      scrollPosition: 0,
      model: false,
      avator: require("../assets/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      title:'数智油田',
      dialogconsult: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: '0123456789ABCDEF',
      carouselItems: [
        {
          img: require("../assets/logo1.png"),
          title: "智能钻井",
          text:
              "着陆段地质层分类预警：基于六个维度数据特征，建立最小AI模型，准确率提升20%。" +
              "<br>" +
              "水平段上下穿层预警：水平段穿层实时判断、上下切角实时预警建立最小模型。",
        },
        {
          img: require("../assets/logo2.png"),
          title: "智能压裂",
          text:
              "影响压裂效果的指标权重分析：影响压裂效果（EUR）的多维地质工程参数的指标权重分析。\n" +
              "<br>" +
              "压裂产量EUR预测：基于给定的多维地质工程参数，对EUR的AI预测模型准确率78%。",
        },
        {
          img: require("../assets/logo3.png"),
          title: "智能采油气",
          text: "磕头机的生产冲次辅助决策：构建智能采油气机器人提供辅助决策。",
        },
        {
          img: require("../assets/logo4.png"),
          title: "地层监测",
          text: "基于光纤的井筒完整性监测：基于光纤DTS温度数据建立对井筒故障监测及位置预警的最小模型。",
        },
        {
          img: require("../assets/logo5.png"),
          title: "设备设施监测",
          text: "设备故障检测：基于开源的滚动轴承数据建立模型，实时判断轴承故障类型、故障大小、故障位置",
        },
      ],
      groupedItems: [],
      scrollY: 0,
      scrollHeight:0,
      scrollTrue:true,
      scrollback:false,
    };
  },
  computed: {
    network() {
      return network
    },
    technology() {
      return technology
    },

  },
  created() {
    localStorage.setItem("home",1)


  },
  watch: {},
  // beforeRouteLeave(to, from, next) {
  //   // 保存滚动位置
  //   this.scrollPosition = this.$refs.totalModel.scrollTop;
  //   next();
  // },

  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    // 通用GPT
    if (sessionStorage.getItem("skip") === '1') {
      this.$nextTick(() => {
        this.skipp1();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("skip") === '2') {
      this.$nextTick(() => {
        this.skipp2();
      });
    }
    // 底部
    else if (sessionStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
    // 油气专业智能软件及GPT应用服务
    else if (sessionStorage.getItem("skip") === '4') {
      this.$nextTick(() => {
        this.skipp4();
      });
    }
    // 油气专业智能软件及GPT应用服务
    else if (sessionStorage.getItem("skip") === '5') {
      this.$nextTick(() => {
        this.skipp5();
      });
    }
    // 油气开发作业GPT应用项目服务
    else if (sessionStorage.getItem("skip") === '6') {
      this.$nextTick(() => {
        this.skipp6();
      });
    }
    else if (sessionStorage.getItem("skip") === '7') {
      this.$nextTick(() => {
        this.skipp7();
      });
    }
    else if (sessionStorage.getItem("skip") === '8') {
      this.$nextTick(() => {
        this.skipp8();
      });
    }
    else if (sessionStorage.getItem("skip") === '10') {
      this.$nextTick(() => {
        this.skipp10();
      });
    }
    else if (sessionStorage.getItem("skip") === '11') {
      this.$nextTick(() => {
        this.skipp11();
      });
    }else if (sessionStorage.getItem("skip") === '12') {
      this.$nextTick(() => {
        this.skipp12();
      });
    }else if (sessionStorage.getItem("skip") === '13') {
      this.$nextTick(() => {
        this.skipp13();
      });
    }else if (sessionStorage.getItem("skip") === '14') {
      this.$nextTick(() => {
        this.skipp14();
      });
    }
    // 添加滚动事件监听器
    this.updateGroupedItems();
  },

  methods: {
    handleScroll() {
      if(this.model===false){
        this.scrollHeight = this.$refs.total.scrollTop;
      }
      else{
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if( this.scrollHeight<100){
        this.scrollTrue=true
      }
      else{
        this.scrollTrue=false
      }
      if( this.scrollHeight<50){
        this.scrollback=false
      }
      else{
        this.scrollback=true
      }
      console.log("gaodu",this.scrollHeight<100)
    },

    uptop(){


      if(this.model===false){
        const element = this.$refs.total;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.total.scrollTop = rect
        });
      }else{
        const elementModel = this.$refs.totalModel;
        const rect = elementModel.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }
    },
    feedback(){
      this.$router.push("/feedbackEn")
      // window.open("https://mall.antonoil.com/gpts_feedback ", "_blank");
    },
    Consult(item){
      console.log(item)
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    show() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },

    gpts() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground() {
      localStorage.setItem("skip", 0)
      window.open("/groundOil", "_blank");
    },
    oilgpt() {
      localStorage.setItem("skip", 0)
      window.open("/gptOil", "_blank");
    },
    // 钻井
    hole() {
      localStorage.setItem("skip", 0)
      window.open("/makeHole", "_blank");
    },
    oilDesign() {
      localStorage.setItem("skip", 0)
      window.open("/gptDesign", "_blank");
    },
    oilTranslate() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    oilTechnology() {
      localStorage.setItem("skip", 0)
      window.open("/gptTechnology", "_blank");
    },
    oilgpt2() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    // 压裂
    frack() {
      localStorage.setItem("skip", 0)
      window.open("/frackOil", "_blank");
    },
    oilgpt3() {
      localStorage.setItem("skip", 0)
      window.open("/gptHole", "_blank");
    },
    // 采油
    getOil() {
      localStorage.setItem("skip", 0)
      window.open("/getOil", "_blank");
    },

    // <!--设备检测-->
    equipment() {
      localStorage.setItem("skip", 0)
      window.open("/monitorEqu", "_blank");
    },

    // 地层监测
    monitor() {
      localStorage.setItem("skip", 0)
      window.open("/monitorOil", "_blank");
    },

    // 油田管理
    menage() {
      localStorage.setItem("skip", 0)
      window.open("/menageOil", "_blank");
    },

    station() {
      localStorage.setItem("skip", 0)
      window.open("/station", "_blank");
    },
    security() {
      localStorage.setItem("skip", 0)
      window.open("/security", "_blank");
    },
    productANxun() {
      localStorage.setItem("skip", 0)
      window.open("/product", "_blank");
    },
    equipmentAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/equipmentAnxun", "_blank");
    },
    technologyAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/technology", "_blank");
    },
    monitorAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/monitorAnxun", "_blank");
    },
    networkAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/pipeline", "_blank");
    },
    basic() {
      localStorage.setItem("skip", 0)
      window.open("/basic", "_blank");
    },
    setting() {
      localStorage.setItem("skip", 0)
      window.open("/setting", "_blank");
    },
    traningAi(){
      window.open("/traning", "_blank");
    },

    consult() {
      window.open("/consult", "_blank");
    },
    constructPlat(){
      window.open("/construct", "_blank");
    },
    business() {
      window.open("/business", "_blank");
    },
    company() {
      window.open("/company", "_blank");
    },
    computingPower() {
      window.open("/computingPower", "_blank");
    },
    traningAi1(){
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1(){
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip",6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip",5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },

    station1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/pipeline")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/setting")
    },

    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },

    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },
    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },
    expo() {
      localStorage.setItem("skip", 0)
      // window.open("https://www.oilgasmall.com/", "_blank");
    },
    job() {
      localStorage.setItem("skip", 0)
      // window.open("https://oilgasjobai.com/", "_blank");
    },
    info() {
      localStorage.setItem("skip", 0)
      // window.open("https://www.oilgasinfoai.com/", "_blank");
    },
    wen() {
      localStorage.setItem("skip", 0)
      // window.open("https://yiyan.baidu.com/", "_blank");
    },
    dou() {
      localStorage.setItem("skip", 0)
      // window.open("https://www.doubao.com/", "_blank");
    },
    xun() {
      localStorage.setItem("skip", 0)
      // window.open("https://xinghuo.xfyun.cn/", "_blank");
    },
    tong() {
      localStorage.setItem("skip", 0)
      // window.open("https://tongyi.aliyun.com/qianwen/", "_blank");
    },
    zhi() {
      localStorage.setItem("skip", 0)
      // window.open("https://chatglm.cn/", "_blank");
    },
    teng() {
      localStorage.setItem("skip", 0)
      // window.open("https://hunyuan.tencent.com/", "_blank");
    },
    tian() {
      localStorage.setItem("skip", 0)
      // window.open("https://home.tiangong.cn/", "_blank");
    },
    ge() {
      localStorage.setItem("skip", 0)
      // window.open("https://yige.baidu.com/", "_blank");
    },
    wan() {
      localStorage.setItem("skip", 0)
      // window.open("https://ai.kezhan365.com/", "_blank");
    },
    yi() {
      localStorage.setItem("skip", 0)
      // window.open("https://aigc.yizhentv.com/", "_blank");
    },

    meitiProduct(){
      // window.open("http://www.oilmates-omm.com/en/h-col-106.html", "_blank");
    },
    meiti3D(){
      // window.open("http://www.oilmates-omm.com/en/h-col-102.html", "_blank");
    },
    meitiDesign(){
      // window.open("http://www.oilmates-omm.com/en/h-col-104.html", "_blank");
    },
    meitiProduct1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/en/h-col-106.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meiti3D1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/en/h-col-102.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meitiDesign1(){
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/en/h-col-104.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    expo1() {
      sessionStorage.setItem("skip", 2)
      // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
      const externalLink = 'https://www.oilgasmall.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    job1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://oilgasjobai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    info1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://www.oilgasinfoai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    wen1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yiyan.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },

    traning(){
      window.open("/traning", "_blank");
    },
    service(){
      window.open("/service", "_blank");
    },
    construct(){
      window.open("/construct", "_blank");
    },
    menageService(){
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    application(){
      window.open("/application", "_blank");
    },
    oilApplication(){
      window.open("/oilApplication", "_blank");
    },

    knowledge(){
      window.open("/gptKnowledge", "_blank");
    },
    data(){
      window.open("/gptData", "_blank");
    },
    wisdom(){
      window.open("/wisdom", "_blank");
    },
    plat(){
      window.open("/plat", "_blank");
    },
    design(){
      window.open("/design", "_blank");
    },
    automation(){
      window.open("/automation", "_blank");
    },

    traning1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/traning")
    },
    service1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/service")
    },
    construct1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/construct")
    },
    menageService1(){
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    application1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/application")
    },
    oilApplication1(){
      sessionStorage.setItem("skip",11)
      this.$router.push("/oilApplication")
    },

    knowledge1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/gptKnowledge")
    },
    data1(){
      this.$router.push("/gptData")
      sessionStorage.setItem("skip",10)
    },
    wisdom1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/wisdom");
    },
    plat1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/plat")
    },
    design1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/design")
    },
    automation1(){
      sessionStorage.setItem("skip",10)
      this.$router.push("/automation")
    },

    position1(){
      const element = this.$refs.oilZixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position2(){
      const element = this.$refs.generalMove;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position3(){
      const element = this.$refs.aiPeixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position4(){
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position5(){
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position6(){
      const element = this.$refs.consultMove;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position7(){
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position8(){
      const element = this.$refs.moveProduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position9(){
      const element = this.$refs.mediaMove;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    position10(){
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      function scrollToElement(element, to, duration) {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;
        setTimeout(() => {
          element.scrollTop = element.scrollTop + perTick;
          if (element.scrollTop === to) return;
          scrollToElement(element, to, duration - 10);
        }, 10);
      }

      scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
    },
    skipp1() {
      const element = this.$refs.general;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp2() {
      const element = this.$refs.zixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp4() {
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    skipp5() {
      const element = this.$refs.professional1;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp6() {
      const element = this.$refs.upload;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp7() {
      const element = this.$refs.station;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp8() {
      const element = this.$refs.technology;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp10() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp11() {
      const element = this.$refs.company;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },

    skipp12() {
      const element = this.$refs.traningConsult;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp13() {
      const element = this.$refs.companyConduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp14() {
      const element = this.$refs.media;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    dou1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://www.doubao.com/';
      this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    xun1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://xinghuo.xfyun.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    tong1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://tongyi.aliyun.com/qianwen/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    zhi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://chatglm.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    teng1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://hunyuan.tencent.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    tian1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://home.tiangong.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    ge1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yige.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    wan1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://ai.kezhan365.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    yi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://aigc.yizhentv.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },

    updateGroupedItems() {
      const startIndex = this.currentIndex;
      this.groupedItems = [];

      for (let i = 0; i < this.itemsPerPage; i++) {
        const group = this.carouselItems.slice(
            startIndex + i,
            startIndex + i + this.itemsPerPage
        );
        this.groupedItems.push(group);
      }
    },
    leftnext() {
      console.log(this.currentIndex);
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateGroupedItems();
      }
    },
    rightnext() {
      if (this.currentIndex < 1) {
        this.currentIndex++;
        this.updateGroupedItems();
      }
    },
    onCarouselChange(index) {
      this.currentIndex = index;
      this.updateGroupedItems();
    },
  },
};
</script>
<style lang="scss" scoped>
.avator {
  border: 2px solid #fff;
}

.userName {
  color: #2f6eff;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total {
  background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 50vh;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    background-image: url("../assets/back1.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .up{
      cursor: pointer;
      position: fixed;
      top:90%;
      right:2%;
      padding:5px;
      width: 2.5vw;
      height:2.5vw;
      border-radius: 50%;
      //border: 5px solid #1295d9;
      background-image: url("../assets/up.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .layer2 {
      margin-top: 4.1495673671199vh;
      text-align: center;

      .title1 {

        height: 8.77626699629172vh;
        font-size: 2.5vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #2f6eff;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin-top: 2vh;
        height: 8.52904820766378vh;
        font-size: 1.25979202772964vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: bold;
        color: #2f6eff;
        line-height: 5.31520395550062vh;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin:0 auto 0 auto;
        width: fit-content;
        height: 6.52904820766378vh;
        font-size: 0.97vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: normal;
        color: #2f6eff;
        line-height: 5.31520395550062vh;
        .hoverTitle{
          display: flex;
          margin-right: 1.5vw;
          cursor: pointer;
          transition:transform 0.3s ease-out;

        }
        .hoverTitle:hover{
          transform:translateY(-5px);
        }
      }
    }

    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }

  .dashedlayer {
    z-index: 8;
    position: absolute;
    top: 92.21260815822002vh;
    left: 0;
    width: 15vw;
    height: 8.52904820766378vh;
    background: linear-gradient(to right, #e9effb 30%, transparent 100%);
  }

  .dashedlayerblue {
    z-index: 9;
    position: absolute;
    top: 91.21260815822002vh;
    left: 0;
    width: 80vw;
    height: 8.52904820766378vh;
    background: linear-gradient(
            to bottom,
            rgba(202, 222, 251, 0.9) 0%,
            transparent 100%
    );
  }

  .layer4 {
    z-index: 2;
    position: absolute;
    top: 92.21260815822002vh;
    left: 4.44829578278452vw;
    width: 89.42807625649913vw;
    height: 8.52904820766378vh;
    background-image: url("../assets/hummer.png");
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .dashed {
    z-index: 1;
    position: absolute;
    top: 67.36711990111248vh;
    left: 10.86077411900635vw;
    width: 80.22068168688619vw;
    height: 75.24474660074166vh;
    background: linear-gradient(to bottom, #ebf1fd 50%, transparent 100%);
  }


  .screen3 {
    z-index: 10;
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 3.78739184177998vh 7.91854419410745vw;
    background:transparent;

    .title {
      margin: 0 auto;
      text-align: center;
       height: 6.23733003708282vh;
      opacity: 1;
      font-size: 1.8vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 3vh auto;
      text-align: left;
      width:75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.96938186019642vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }
    .pro2 {
      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 35.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
           transition: transform 0.5s ease;
          border-radius: 9px;
          width: 100%;
          height: 100%;
          background-color: white;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 250px;
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin:1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          height: 8.2vh;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }


  }

  .screenConsult {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 3.78739184177998vh 7.91854419410745vw 3.78739184177998vh 7.91854419410745vw;
    background: white;

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width:75vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .title {

       height: 6.23733003708282vh;
      opacity: 1;
      font-size: 1.8vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 3vh auto;
      text-align: left;
      width:75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }

    .pro1 {
      height: 45.45364647713226vh;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand1 {
        cursor: pointer;
        width: 100%;
        height: 45.45364647713226vh;
        padding: 0.98887515451174vh 0.32432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {

          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          border: 2px solid #FFFFFF;
          box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
        }

        .photo {
          border: 2px solid #FFFFFF;
          background-image: url("../assets/pro1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 21.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 5.22496909765142vh 1.2vw auto 1.2vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 600;
          //color: #2168DB;
          line-height: 3.71940667490729vh;
        }

      }

      .grand1:hover {
        transform: scale(1.05);

        .title1 {
          color: #2168db;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          font-size: 0.80878105141537vw;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: 25.30329289428076vw;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 5px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh 0 3vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 19vw;
          height: 17.44004944375773vh;
        }

        .title1 {
          opacity: 4;
          margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;
          text-align: left;
          width: 12vw;

          height: 3vh;
          font-size: 0.97094165222415vw;
          white-space: nowrap; //换行
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          overflow: hidden;

          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.51940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          box-shadow: 5px 0 14px 0px #9abaf3;
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 32.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin:1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height:3.1940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo {
          display: none;
        }

        .title1 {
          position: absolute;
          left:1.8vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          word-break: normal;
          margin-top: 8.22496909765142vh;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          position: absolute;
          left: 1.8vw;
          cursor: pointer;
          display: block;
          color: white;
        }
      }
    }


  }

  .screenConsult1 {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 3.78739184177998vh 7.91854419410745vw 3.78739184177998vh 7.91854419410745vw;
    background: white;

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width:75vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .title {

       height:fit-content;
      opacity: 1;
      font-size: 1.7vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 2vh auto 3vh auto;
      text-align: left;
      width:75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }

    .pro1 {
      height: 37.45364647713226vh;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand1 {
         transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        height: 37.45364647713226vh;
        padding: 0.98887515451174vh 0.32432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          width: 100%;
          height: 100%;
          background: white;;
          border: 2px solid #FFFFFF;
          box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
        }

        .photo {
          border: 2px solid #FFFFFF;
          background-image: url("../assets/pro1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 21.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 5.22496909765142vh 0.5vw auto 0.5vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 600;
          //color: #2168DB;
          line-height: 3.01940667490729vh;
        }

      }

      .grand1:hover {
        transform: translateY(-10px);

        .title2 {
          font-size: 0.80878105141537vw;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 1vw;
      row-gap: 1.8vh;

      .grand1 {
         transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        position: relative;

        .back {  transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 5px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh 0 3vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 25vw;
          border-radius: 9px;
          height: 17.44004944375773vh;
        }

        .title1 {
          opacity: 4;
          margin: 3vh 0 1.22496909765142vh 0.85540150202195vw;
          text-align: left;
          width: 12vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //word-break: break-all;
          height: 6.8vh;
          font-size: 0.97094165222415vw;
          //white-space: nowrap; //换行
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 0.15540150202195vw 0.94437577255871vh 1.54437577255871vh;
          ////text-align: justify;
          //word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          height:8.1vh;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.7194066749vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          transform: translateY(-10px);
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 1.52432120161756vw 0.98887515451174vh 1.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2.94437577255871vh 1.35540150202195vw;
          ////text-align: justify;
          //word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo {
          display: none;
        }

        .title1 {
          position: absolute;
          left: 2.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          position: absolute;
          left: 2.85540150202195vw;
          cursor: pointer;
          display: block;
          color: white;
        }
      }
    }
    .pro4 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: 35.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #ffffff;;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin:1.5vh  1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          height: 14vh;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo {
          display: none;
        }

        .title1 {
          position: absolute;
          left: 1.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }
    }

  }
  .screen8 {

    width: 100%;
    height: fit-content;
    padding: 3.78739184177998vh 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 1.8vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: left;
      width: 75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }
    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.96938186019642vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 0 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
          height: fit-content;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          padding: 3vh 1.5vw 0 1.5vw;
          display: flex;
          height: fit-content;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 1.5vh
        }

        .title2 {
          opacity: 4;
          margin: 0 1.355401502vw 0.5vh 1.355401502vw;
          text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          height: 10.8vh;
          line-height: 2.7194066749vh;
        }
        .title3 {
          opacity: 4;
          grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
          margin: 2vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: grid;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }
    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 0 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
          height: fit-content;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          padding: 3vh 1.5vw 0 1.5vw;
          display: flex;
          height: fit-content;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 3vh 1.355401502vw 0.5vh 1.355401502vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          height: 10.8vh;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }

  }
  .screen10 {

    width: 100%;
    height: fit-content;
    padding: 3.78739184177998vh 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    //background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    background-color: white;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 1.8vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: left;
      width: 75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }
    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.96938186019642vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 0 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-color: #e9eef9;
          //background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height:fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          margin: 3vh 1vw;
          justify-content: center;
          align-items: center;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          height: 6.8vh;
          line-height: 3.4vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          transform: translateY(-10px);

        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-color: #e9eef9;
          //background-image: url("../assets/servicesBanner2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          margin: 3vh 1vw;
          justify-content: center;
          align-items: center;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          height: 6.8vh;
          line-height: 3.4vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          background-color: #e9eef9;
          border-radius: 9px;
          position: relative;
          //background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          margin: 3vh 1vw;
          justify-content: center;
          align-items: center;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          height: 6.8vh;
          line-height: 3.4vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }


    .pro3 {
      border-radius: 9px;
      background-color: white;
      height: fit-content;
      width: 100%;
      padding: 4.8442521631644vh 8.09763142692085vw 8.8442521631644vh 8.09763142692085vw;

      .title {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.8vw;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .title2 {
        margin: 0 auto;
        text-align: center;
        width: 60.96938186019642vw;
        font-size: 1.0287117273vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        height: 8.7873918418vh;
        line-height: 4.94437577255871vh;
      }

      .banner {

        border-radius: 9px;
        height: 30.53559950556242vh;
        //margin-top: 8.44993819530284vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //column-gap: 1vw;
        padding: 1.11248454882571vh 0.69324090121317vw;

        .back1 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          box-shadow: 5px 0 14px 0px #d4e4ff;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 0.9709416522vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv1.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back4 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          box-shadow: 5px 0 14px 0px #d4e4ff;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 0.9709416522vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/environment.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back2 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          box-shadow: 5px 0 14px 0px #d4e4ff;
          overflow: hidden;
          opacity: 1;
          border: 1px solid white;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 0.9709416522vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv2.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back3 {
          border: 1px solid white;
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          box-shadow: 5px 0 14px 0px #d4e4ff;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 0.9709416522vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }


          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv3.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }
      }
    }

    .pro4 {
      height: 87.72805933250927vh;
      width: 100%;
      padding: 13.8442521632vh 7.1854419410745vw 0 7.1854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        height: 48.83559950556242vh;
        margin-top: 4.44993819530284vh;
        width: 100%;
        column-gap: 1.61756210283073vw;
        padding: 1.11248454882571vh 1.69324090121317vw;
        position: relative;

        .carousel-group {
          padding: 0 2.76874638937031vw;
          display: flex;
        }

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .disabled-button {
          opacity: 0.5; /* Adjust the opacity as needed */
        }

        .disabled-buttonright {
          opacity: 0.5; /* Adjust the opacity as needed */
          background: gray;
          /* Add any other styles you want for the disabled state */
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 1.15540150202195vw;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .carousel-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48.83559950556242vh;
          margin: 0 0.5108030040439vw;
          padding: 0.98887515451174vh 0.69324090121317vw;

          opacity: 1;

          .back {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #2f6eff linear-gradient(to bottom, #cfe1ff 0%, #f0f6ff 100%);
            box-shadow: 3px 0px 8px 0px #d4e4ff;
            border-radius: 2px 2px 2px 2px;
            position: relative;

            .backlogo {
              margin: 3.11248454882571vh auto;
              text-align: center;
              height: 8.31520395550062vh;
              width: 8.3152039555vh;
            }

            .backtitle {
              margin-top: 2.81248454882571vh;
              text-align: center;
              height: 1.85414091470952vh;
              font-size: 1.5761821366vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.05414091470952vh;
            }

            .backtext {
              padding: 0 1.5248454882571vw;
              margin-top: 3.01248454882571vh;
              //text-align: justify;
              font-size:0.8087810514vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.7194066749vh;
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168db;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 6.55414091470952vw;
            }
          }
        }

        .carousel-item:hover {
          .backtitle {
            color: #2168db;
          }

          .backconsult {
            display: flex;
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            align-items: center;
            justify-content: center;
            height: 2.95414091470952vh;
            left: 5.55414091470952vw;

            color: transparent;
            /* 使用 @keyframes 定义颜色变化的动画 */
            animation: changeColor 0.2s ease-out 0.3s forwards;
          }

          /* 定义 @keyframes 动画，从透明色到蓝色 */
          @keyframes changeColor {
            from {
              color: rgba(33, 104, 219, 0); /* 透明色 */
            }
            to {
              color: rgba(33, 104, 219, 1); /* 蓝色 */
            }
          }

          .backconsultArrow {
            transform: translateX(2vw);
            transition: transform 0.3s ease-out;
          }
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .pro5 {
      height: 45.0061804697157vh;
      width: 100vw;
      padding: 8.8442521632vh 12.42056614673599vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        height: 9.02348578491965vh;
        font-size: 2.1060658579vw;
        text-align: center;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #2f6eff;
        line-height: 2.84301606922126vh;
      }

      .highlight1 {
        margin: 3.44993819530284vh auto auto auto;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 5.56242274412855vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2168db;
        border-radius: 23px 23px 23px 23px;
        opacity: 1;
        font-size: 1.27vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.84301606922126vh;
        cursor: pointer;
      }

      .highlight1:hover {
        color: #2168db;
        background: rgba(255, 255, 255, 0.5);
        border: #2168db 1px solid;
      }
    }
  }
  .screenGeneral {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 3.78739184177998vh 7.91854419410745vw;
    background: transparent;

    .title {
      margin: 0 auto;
      text-align: center;
       height: 6.23733003708282vh;
      opacity: 1;
      font-size: 1.8vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto 3vh auto;
      text-align: left;
      width:75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 66.96938186019642vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand2 {
        cursor: pointer;
        width: 15.13575967648758vw;
        height: 42.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo {
          display: none;
        }

        .title1 {
          position: absolute;
          left: 1.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }
    }

    .pro3 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;

          height: fit-content;
          background: #ebf0fb;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }
        .color {
          width: 100%;
          height: fit-content;
          background: linear-gradient(98.44deg, #faefdc 0%, #e5ebf8 50%,#faefdc 100%);

          text-align: center;
          justify-content: center;
          align-items: center;
          display: flex;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: fit-content;
          }

        }




        .title1 {
          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          height:6vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw  2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
          height: 8.2vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        //.back {
        //  background-color: #2F6EFF;
        //}

        .photo {
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          transform: scale(1.05);
        }

        //.title1 {
        //  position: absolute;
        //  left: 1.85540150202195vw;
        //  top: 2.22496909765142vh;
        //  height: 2.71940667490729vh;
        //  color: white;
        //  font-size: 0.97094165222415vw;
        //}
        //
        //.title2 {
        //  margin-top: 8.22496909765142vh;
        //  text-align: justify;
        //  color: white;
        //  font-size: 0.80878105141537vw;
        //}

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }


      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 45vh;
        padding: 0 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;
        .back {
          width: 100%;
          min-height: fit-content;
          background: #ebf0fb;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }
        .color {
          width: 100%;
          height: fit-content;
          background: linear-gradient(98.44deg,#ADD8E6 0%, #e5ebf8 50%, #ADD8E6 100%);
          text-align: center;
          justify-content: center;
          align-items: center;
          display: flex;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: fit-content;
          }

        }



        .title1 {
          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          height:6vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw  2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
          height: 8.2vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        //.back {
        //  background-color: #2F6EFF;
        //}

        .photo {
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          transform: scale(1.05);
        }

        //.title1 {
        //  position: absolute;
        //  left: 1.85540150202195vw;
        //  top: 2.22496909765142vh;
        //  height: 2.71940667490729vh;
        //  color: white;
        //  font-size: 0.97094165222415vw;
        //}
        //
        //.title2 {
        //  margin-top: 8.22496909765142vh;
        //  text-align: justify;
        //  color: white;
        //  font-size: 0.80878105141537vw;
        //}
        //
        //.consult {
        //  cursor: pointer;
        //  display: block;
        //  color: white;
        //}

      }

    }
  }

  .screenGeneral1 {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 3.78739184177998vh 7.91854419410745vw;
    background: white;

    .title {
      margin: 0 auto;
      text-align: center;
       height: 6.23733003708282vh;
      opacity: 1;
      font-size: 1.8vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.9693818602vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .light {
      margin: 0 auto 3vh auto;
      text-align: left;   width:75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 66.96938186019642vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.94437577255871vh;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand2 {
        cursor: pointer;
        width: 15.13575967648758vw;
        height: 35.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #ebf0fb;;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin:1.5vh  1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          height: 14vh;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo {
          display: none;
        }

        .title1 {
          position: absolute;
          left: 1.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }
    }

  }
  .screen4 {
    z-index: 10;
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 3.78739184177998vh 8.09763142692085vw;
    background: white;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }
    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.9693818602vw;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: left;
      width: 75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;

    }



    .pro2 {
      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #ebf0fb;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }
        .title1 {
          opacity: 4;
          margin : 2.22496909765142vh auto 2.22496909765142vh auto;
          padding:3vh 1vw 1vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }


  }
  .screen6 {

    width: 100%;
    height: fit-content;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
       height: 6.23733003708282vh;
      opacity: 1;
      font-size: 1.8vw;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 700;
      line-height: 4.23733003708282vh;
    }

    .light {
      margin: 0 auto;
      text-align: center;
      width:75vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 4.94437577255871vh;
    }
    .companyIntroduce {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 3vh;

      .grand3 {
        cursor: pointer;
        width: 100%;
           height: 28vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
             height: 28vh;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {

            justify-content: center;
            text-align: center;
            align-items: center;
            width:15vw;
            margin: auto auto auto auto;
            height: fit-content;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }
          .photoHidden{
            height: 100%;
            position:absolute;
            right:0;
            opacity: 0.2;
          }
          .title{
            width:100%;
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width:fit-content;
              margin: 3vh auto 0 1.355401502vw;
              height:4.2vh;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color:white;
              width:100%;
              padding: 1vh 1.355401502vw;
              text-align: left;
              font-size:1vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              line-height:3.5vh;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 3vh;
              right:1.355401502vw;
              align-items: center;
              width:fit-content;
              height: 3.2vh;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          };


        }

      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
      .grand2 {
        cursor: pointer;
        width: 100%;
           height: 28vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
             height: 28vh;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {

            justify-content: center;
            text-align: center;
            align-items: center;
            width:15vw;
            margin: auto auto auto auto;
            height: fit-content;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }
          .photoHidden{
            height: 100%;
            position:absolute;
            right:0;
            opacity: 0.2;
          }
          .title{
            width:100%;
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width:fit-content;
              margin: 3vh 0 0  1.355401502vw ;
              height:4vh;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color:white;
              width:100%;
              padding: 1vh 1.355401502vw;
              text-align: left;
              font-size:1vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              line-height:3.5vh;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 3vh;
              right:1.355401502vw;
              align-items: center;
              width:fit-content;
              height: 3vh;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          };


        }

      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }

      .grand4 {
        cursor: pointer;
        width: 100%;
           height: 28vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
             height: 28vh;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {

            justify-content: center;
            text-align: center;
            align-items: center;
            width:15vw;
            margin: auto auto auto auto;
            height: fit-content;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }
          .photoHidden{
            height: 90%;
            position:absolute;
            right:0;
            opacity: 0.2;
          }
          .title{
            width:100%;
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width:fit-content;
              margin: 3vh 0 0 1.355401502vw;
              height:4vh;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color:white;
              width:100%;
              padding: 1vh 1.355401502vw;
              text-align: left;
              font-size:1vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              line-height:3.5vh;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 3vh;
              right:1.355401502vw;
              align-items: center;
              width:fit-content;
              height: 3vh;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          };


        }

      }

      .grand4:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }
    .pro1 {
      background-color: white;
      height: 115.3374536464771vh;
      width: 100%;
      padding: 13.8442521631644vh 7.91854419410745vw 0 7.91854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        margin-top: 6.79851668726823vh;
        width: 100%;
        height: 4.94437577255871vh;
        display: grid;
        grid-template-columns:
          minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
          minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        border-bottom: 1px #2f6eff solid;
        text-align: center;
        opacity: 1;

        .bannertitle {
          cursor: pointer;
          height: 2.59579728059333vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2F6EFF;
          line-height: 2.84301606922126vh;

          .span {
            margin-top: 1.7vh;
            margin-left: 2.855863662623vw;
            width: 5.19930675909879vw;
            border-bottom: 5px #2f6eff solid;
          }
        }
      }

      .card {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card2 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt2.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card3 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt3.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card4 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt4.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 5.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card5 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt5.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card6 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt6.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line4 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 4.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card7 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt7.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line4 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 4.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      padding:  3vh 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 1vw;
      row-gap: 2vh;

      .grand1 {
        cursor: pointer;
        width:100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          .details{
            position: absolute;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 225px;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          padding: 3vh 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 0.9709416522vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height:3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }



        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          transform: translateY(-10px);

        }
      }

      .grand2 {
        cursor: pointer;
        width:100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height:fit-content;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          .details{
            position: absolute;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 225px;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 0.9709416522vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height:3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }



        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }
      }


      .grand3 {
        cursor: pointer;
        width:100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          .details{
            position: absolute;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 225px;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 0.9709416522vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height:3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:0.8087810514vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }



        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          transform: translateY(-10px);

        }
      }
    }

    .pro3 {
      border-radius: 9px;
      background-color: transparent;
      height: fit-content;
      width: 100%;
      padding: 4.8442521631644vh 8.09763142692085vw 8.8442521631644vh 8.09763142692085vw;

      .title {
        margin: 0 auto;
        //text-align: left;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.8vw;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .title2 {
        margin: 0 auto 4vh auto;
        text-align: left;
        width: 75vw;
        height: fit-content;
        font-size: 1vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
      }

      .banner {

        border-radius: 9px;
        height: 30.53559950556242vh;
        //margin-top: 8.44993819530284vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;
        padding: 1.11248454882571vh 0.69324090121317vw;

        .back1 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          box-shadow: 5px 0 14px 0px #d4e4ff;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 0.9709416522vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv1.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back4 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          box-shadow: 5px 0 14px 0px #d4e4ff;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 0.9709416522vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv4.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back2 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          box-shadow: 5px 0 14px 0px #d4e4ff;
          overflow: hidden;
          opacity: 1;
          border: 1px solid white;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 0.9709416522vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv2.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back3 {
          border: 1px solid white;
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          box-shadow: 5px 0 14px 0px #d4e4ff;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 0.9709416522vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }


          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv3.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }
      }
    }


    .pro5 {
      height: 35.0061804697157vh;
      width: 100vw;
      padding: 3vh 12.42056614673599vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        margin-top: 5vh;
        height: 9.02348578491965vh;
        font-size: 2.1060658579vw;
        text-align: center;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #2f6eff;
        line-height: 2.84301606922126vh;
      }

      .highlight1 {
        margin: 3.44993819530284vh auto auto auto;
        width: fit-content;
        padding:2.4624227441vh 1.1989601386vw;
        height: 5.56242274412855vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2168db;
        border-radius: 23px 23px 23px 23px;
        opacity: 1;
        font-size: 1.27vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.84301606922126vh;
        cursor: pointer;
      }

      .highlight1:hover {
        color: #2168db;
        background: rgba(255, 255, 255, 0.5);
        border: #2168db 1px solid;
      }
    }
  }

}

.totalModel {
 background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  .up{
    cursor: pointer;
    position: fixed;
    top:90%;
    right:3%;
    padding:5px;
    width: 45px;
    height:45px;
    z-index: 999999999;
    border-radius: 50%;
    //border: 5px solid #1295d9;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .feedback{
    cursor: pointer;
    position: fixed;
    top:48%;
    font-size: 12px;
    z-index: 999999999999;
    right:0px;
    background-color: #bf0224;
    padding:5px;
    width: fit-content;
    height:fit-content;
    color: white;
    padding: 5px 8px;
    display: flex;
    justify-content: center;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border: 5px solid #1295d9;
  }
  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 480px;
    padding: 30px 2.6875vw;
    background-image: url("../assets/backModel.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      .title1 {
        margin: 0 auto;
        width: fit-content;
        height: 23px;
        font-size: 23px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 500;
        color: #111111;
        line-height: 8.23733003708282vh;
      }

      .title2 {
        margin: 35px auto 0 auto;
        width: fit-content;
        height: 17px;
        font-size: 14px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 20px auto 0 auto;
        width: fit-content;
        height: 35px;
        font-size: 8px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: bold;
        color: #2f6eff;
        line-height: 22px;
      }
    }

    .layer3 {
      position: absolute;
      display: flex;
      bottom: 15px;
      opacity: 1;
      width: 90%;
      justify-content: center;

      .communicity {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2f6eff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

      .communicityGpt {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

    }
  }

  .screen3 {
    z-index: 10;
    height: fit-content;
    padding: 20px 4.6875vw;
    background: rgba(255, 255, 255, 1);

    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 16px;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 20px;
    }
    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0.92432120161756vw 0.98887515451174vh 0.69324090121317vw;
        opacity: 1;

        .back {
          width: 100%;
          height: 120px;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          width: 100%;
          height: fit-content;
          background-color: white;;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }
        .photo {
          background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          height: 100%;
        }

        .title1 {
          opacity: 4;
          padding-top: 5px;
          text-align: left;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 26px;
        }

        .title2 {
          opacity: 4;
          width: 100%;
          margin: 3px 10px 5px 0;
          padding: 2px 10px 10px 10px;
          height: fit-content;
          // text-align: justify;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 20px;
        }
      }

    }
    .pro11 {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0.92432120161756vw 0.98887515451174vh 0.69324090121317vw;
        opacity: 1;

        .back {
          width: 100%;
          height: 120px;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: #ebf0fb;
          box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;

        }
        .back1 {
          width: 100%;
          height: fit-content;
          background-color: #ebf0fb;;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          box-shadow: 5px 0 14px 0 #d4e4ff;
          border:white 3px solid;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }
        .photo {
          background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          height: 100%;
        }

        .title1 {
          opacity: 4;
          padding-top: 5px;
          text-align: left;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 26px;
        }

        .title2 {
          opacity: 4;
          width: 100%;
          margin: 3px 10px 5px 0;
          padding: 2px 10px 10px 10px;
          height: fit-content;
          // text-align: justify;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 20px;
        }
      }

    }
    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 7.5px 6px 7.5px 10px;
        opacity: 1;
        position: relative;
        margin: 7.5px 0;

        .back {
          width: 100%;
          height: 100%;
          background-color: #EFF4FF;;
          padding: 0 7.5px;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 5px;
          display: flex;
        }

        .photo {
          border-radius: 50%;
          background-color: #dde9fd;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1.25;
          margin: auto 0 auto 0;
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          width: 68px;
          height: 68px;

          .img {
            width: 48px;
            height: 19px;
          }
        }

        .title1 {
          opacity: 4;
          width: 200px;
          white-space: nowrap; //换行
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 20px;
          text-align: left;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: 500;
          line-height: 26px;
          //color: #0a0a0a;
        }

        .title2 {
          opacity: 4;
          width: 58vw;
          margin: 5px 0 10px 0;
          //text-align: justify;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 22px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }
  }

  .screenConsult {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    padding: 20px 4.6875vw;
    background: white;

    .title {
      //text-align: center;
      height: fit-content;
      width: 95%;
      opacity: 1;
      font-size: 16px;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 20px;
    }

    .light {
      margin: 15px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand1 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 9px 6px;
        opacity: 1;

        .back {
          width: 100%;
          height: 100%;
          background: #edf2fd;
          border: 2px solid #FFFFFF;
          box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
        }

        .photo {
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          background-image: url("../assets/pro1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 123px;
        }

        .title1 {
          opacity: 4;
          margin: 5px 0 5px 0;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          font-size: 14px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          color: #0a0a0a;
          line-height: 25px;
        }

      }


    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 7.5px 6px;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background-color: white;;
          border: 1px solid white;
          padding: 0 7.5px;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 5px;
          display: flex;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 10px 0 10px 0;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 35vw;
          height: 95px;
        }

        .title1 {
          opacity: 4;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          width: 200px;
          margin-top: 7.5px;
          text-align: left;
          //justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: bold;
          height: 44px;
          line-height: 22px;
        }

        .title2 {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          height: 45px;
          opacity: 4;
          width: 53vw;
          margin: 5px 0 10px 0;
          //text-align: justify;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 15px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }

    .pro3 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 16px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 110px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 16px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

    }
  }

  .screenConsult1 {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    padding: 20px 4.6875vw;
    background: white;

    .title {
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 16px;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 20px;
    }

    .light {
      margin: 15px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .pro3 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 0;

      .grand1 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: #e9effb;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }

        .title1 {
          opacity: 4;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 15px auto;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          text-align: center;
          justify-content: center;
          align-items: center;
          height: 36px;
          font-size: 14px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: 500;
          //color: #0a0a0a;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          height: 96px;
          margin: 15px 8px 20px 8px;
          //text-align: justify;

          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 110px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

    }


    .pro4 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 0;

      .grand1 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: #e9effb;;;
          box-shadow: 3px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          border-top-right-radius:9px;
          border-top-left-radius: 9px;

          background: linear-gradient(98.44deg, #ADD8E6 0%, #e5ebf8 50%, #ADD8E6 100%);
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width:100%;
          height: fit-content;
        }
        .color{
          width: 100%;
          height: fit-content;
          background: linear-gradient(98.44deg, #ADD8E6 0%, #e5ebf8 50%, #ADD8E6 100%);
          text-align: center;
          justify-content: center;
          align-items: center;
          display: flex;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          justify-content: center;
          align-items: center;
          height:36px;
          font-size: 14px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #0a0a0a;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: #D4E4FF;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 110px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 58px;
          font-size: 16px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

    }
  }

  .screenGeneral {
    z-index: 10;
    background-color: transparent;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    padding: 20px 4.6875vw;

    .title {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 16px;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 20px;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 2px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: 120px;
        padding: 9px 8px 9px 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          padding: 0 5px;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          //display: flex;
        }

        .photo {
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 8px auto 6px auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 35px;
          height: 40px;
        }

        .title1 {
          opacity: 4;
          margin-top: 7.5px;
          text-align: center;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          color: #0a0a0a ;
          line-height: 20px;
        }

        .title2 {
          width: 110px;
          opacity: 4;
          margin: 5px 3px 5px 0;
          height: 14px;
          word-wrap: break-word; //换行
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }
    }

  }

  .screen6 {

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    background-color: transparent;
    padding: 0 4.6875vw 0 4.6875vw;

    .title {
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 16px;
      //color: #53A3F7;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .companyIntroduce {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;

      .grand3 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height:280px;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {

            justify-content: center;
            text-align: center;
            align-items: center;
            width:59px;
            margin: 10px auto auto 20px;
            height:10px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }
          .photoHidden{
            top:30%;
            width: 100%;
            position:absolute;
            right:0;
            opacity: 0.2;
          }
          .title{
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width:273px;
              margin : 25px auto 10px auto;
              height: 18px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color:white;
              width:100%;
              margin-top:-2px;
              padding:0 20px 35px 10px;
              text-align: left;
              height: fit-content;
              font-size:12px;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              line-height:22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right:10px;
              align-items: center;
              width:223px;
              height: 14px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          };


        }

      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 280px;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {

            justify-content: center;
            text-align: center;
            align-items: center;
            width:59px;
            margin: 10px auto auto 20px;
            height:10px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }
          .photoHidden{
            top:8%;
            width: 100%;
            position:absolute;
            right:0;
            opacity: 0.2;
          }
          .title{
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width:89px;
              margin : 25px auto 10px auto;
              height: 17px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color:white;
              width:100%;
              margin-top:-2px;
              padding:0 20px 35px 10px;
              text-align: left;
              height: fit-content;
              font-size:12px;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              line-height:22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right:10px;
              align-items: center;
              width:280px;
              height: 14px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          };



        }

      }


      .grand4 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 270px;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {

            justify-content: center;
            text-align: center;
            align-items: center;
            width:97px;
            margin: 10px auto auto 20px;
            height:20px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }
          .photoHidden{
            top:8%;
            width: 100%;
            position:absolute;
            right:0;
            opacity: 0.2;
          }
          .title{
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width:275px;
              margin : 25px auto 10px auto;
              height: 17px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color:white;
              width:100%;
              margin-top:10px;
              padding:0 20px 35px 10px;
              text-align: left;
              height: fit-content;
              font-size:12px;
               font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              line-height:22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right:10px;
              align-items: center;
              width:154px;
              height: 14px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          };



        }

      }


    }

    .pro1 {
      background-color: white;
      height: 115.3374536464771vh;
      width: 100%;
      padding: 13.8442521631644vh 7.91854419410745vw 0 7.91854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        margin-top: 6.79851668726823vh;
        width: 100%;
        height: 4.94437577255871vh;
        display: grid;
        grid-template-columns:
          minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
          minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        border-bottom: 1px #2f6eff solid;
        text-align: center;
        opacity: 1;

        .bannertitle {
          cursor: pointer;
          height: 2.59579728059333vh;
          font-size: 0.97094165222415vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          //color: #2F6EFF;
          line-height: 2.84301606922126vh;

          .span {
            margin-top: 1.7vh;
            margin-left: 2.855863662623vw;
            width: 5.19930675909879vw;
            border-bottom: 5px #2f6eff solid;
          }
        }
      }

      .card {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }
        }
      }

      .card2 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt2.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card3 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt3.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card4 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt4.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 5.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card5 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt5.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card6 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt6.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line4 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 4.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card7 {
        margin-top: 2.22496909765142vh;
        background-image: url("../assets/gpt7.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line4 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 4.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 15px;

      .grand1 {
        cursor: pointer;
        width:100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;
        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 220px;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 15px 2px 12px 2px;
          text-align: center;
          height: fit-content;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding:0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          //text-align: justify;
          height:fit-content;
          font-size:12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 20px;
        }


        .consult {
          position: absolute;
          bottom:15px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right:15px;
        }
      }
      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;
        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height:220px;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 20px 2px 12px 2px;
          text-align: center;
          height: fit-content;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding:0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          //text-align: justify;
          height:fit-content;
          font-size:12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 20px;
        }



        .consult {
          position: absolute;
          bottom:15px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right:15px;
        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 220px;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 15px 2px 12px 2px;
          text-align: center;
          height: fit-content;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding:0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          //text-align: justify;
          height:fit-content;
          font-size:12px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          line-height: 20px;
        }



        .consult {
          position: absolute;
          bottom:15px;
          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right:15px;
        }
      }


    }

    .pro3 {
      margin-left: -20px;
      background-color: transparent;
      height: fit-content;
      width: 100vw;
      padding: 20px 15px 20px 15px;
      position: relative;
      //background-image: url("../assets/adv.jpg");
      //background-size: cover;
      //background-position: center;
      //background-repeat: no-repeat;
      .back {
        width: 100%;
        height: 500px;
        background-image: url("../assets/advantage.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .title {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 18px;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 26px;
      }

      .title2 {
        margin: 15px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .banner {
        height: fit-content;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 15px;

        .back1 {
          cursor: pointer;
          position: relative;
          width: 100%;
          height: fit-content;
          //box-shadow: 5px 0 2px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;
          //background: #D4E2FF;
          .backTitle1 {
            //position: absolute;
            margin-top: 5px;
            color: #2168db;
            height: 32px;
            width:100%;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
          }

          .span {
            margin: 2px auto;
            width: 150px;
            text-align: center;
            background-color: #ffffff;
            height: 2px;
          }

          .backTitle2 {
            margin: 5px auto 0 auto;
            height: fit-content;
            width: 95%;
            text-align: center;
            font-size: 12px;
            font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            //color: #2168db;
          }
        }

      }
    }

    .pro4 {
      height: 87.72805933250927vh;
      width: 100%;
      padding: 13.8442521632vh 7.1854419410745vw 0 7.1854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        height: 48.83559950556242vh;
        margin-top: 4.44993819530284vh;
        width: 100%;
        column-gap: 1.61756210283073vw;
        padding: 1.11248454882571vh 1.69324090121317vw;
        position: relative;

        .carousel-group {
          padding: 0 2.76874638937031vw;
          display: flex;
        }

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .disabled-button {
          opacity: 0.5; /* Adjust the opacity as needed */
        }

        .disabled-buttonright {
          opacity: 0.5; /* Adjust the opacity as needed */
          background: gray;
          /* Add any other styles you want for the disabled state */
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 1.15540150202195vw;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .carousel-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48.83559950556242vh;
          margin: 0 0.5108030040439vw;
          padding: 0.98887515451174vh 0.69324090121317vw;

          opacity: 1;

          .back {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #2f6eff linear-gradient(to bottom, #cfe1ff 0%, #f0f6ff 100%);
            box-shadow: 3px 0px 8px 0px #d4e4ff;
            border-radius: 2px 2px 2px 2px;
            position: relative;

            .backlogo {
              margin: 3.11248454882571vh auto;
              text-align: center;
              height: 8.31520395550062vh;
              width: 8.3152039555vh;
            }

            .backtitle {
              margin-top: 2.81248454882571vh;
              text-align: center;
              height: 1.85414091470952vh;
              font-size: 1.5761821366vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.05414091470952vh;
            }

            .backtext {
              padding: 0 1.5248454882571vw;
              margin-top: 3.01248454882571vh;
              ////text-align: justify;
              font-size:0.8087810514vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.7194066749vh;
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
              font-weight: 400;
              color: #2168db;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 6.55414091470952vw;
            }
          }
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .pro5 {
      height: fit-content;
      width: 100vw;
      margin-left: -20px;
      padding: 20px 4.6875vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        text-align: center;
        height: fit-content;
        opacity: 1;
        font-size: 18px;
        //color: #53A3F7;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 700;
        line-height: 26px;
      }

      .highlight1 {
        margin: 20px auto auto auto;
        cursor: pointer;
        font-size: 17px;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        padding: 10px 4.6875vw;
        height: 40px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden;
      }

    }
  }

  .screen7 {

    width: 100%;
    bottom: 0;
    height: fit-content;
    background-color: #333333;

    .foot {
      padding-top: 10px;
      text-align: center;
      border-top: 1px solid #373b4f;

      .flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        text-align: center;
        width: fit-content;
        color: #999999;
        margin: 0;
      }

      span {
        padding: 0 10px;
        color: #999999;
        font-size: 12px;
      }

      p:nth-child(1),
      p:nth-child(3) {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
      }

      p.red {
        color: #ef3e4f;
        /* font-weight: bold; */
        font-size: 12px;
        line-height: 30px;
      }

      p.red a {
        color: #999999;
      }
    }

    .yuming {
      color: white;
    }

    .left {
      display: flex;
      color: #ffffff;

      .left1 {
        width: 9.51415366839977vw;
        font-size: 0.8709416522vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.33745364647713vh;
      }

      .left2 {
        margin-left: 4.15944540727903vw;
        width: 20.51415366839977vw;
        font-size: 0.8709416522vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.33745364647713vh;
      }
    }

    .right {
      margin-top: -1vh;
      display: flex;
      color: #ffffff;

      .left1 {
        width: 7.23916811091854vw;
        font-size: 0.8709416522vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 3.33745364647713vh;

        .up {
          width: 100%;
          height: auto;
          background-image: url("../assets/attention.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .left2 {
        margin-left: 4.15944540727903vw;
        width: 7.23916811091854vw;
        font-size: 0.8709416522vw;
        font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 3.33745364647713vh;

        .up {
          width: 100%;
          height: auto;
          background-image: url("../assets/attention.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  //.screen4Plus{
  //  left: 10.86077411900635vw;
  //  position: absolute;
  //  top: 176.9962917181706vh;
  //  margin: 0 auto;
  //  width: 78.22068168688619vw;
  //  background: rgba(184, 223, 246, 0.4);
  //  box-shadow: 5px 0 2px 0px #D4E4FF;
  //  height: fit-content;
  //
  //  border-radius: 9px;
  //  padding: 4.0242274412855vh 1.09763142692085vw;
  //  .title{
  //    margin: 0 0.8vw;
  //    height: 6.05686032138443vh;
  //    opacity: 1;
  //    font-size: 1.67533217793183vw;
  //    //color: #53A3F7;
  //    font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //    font-weight: 700;
  //    line-height: 2.71940667490729vh;
  //  }
  //  .screen4 {
  //    display: grid;
  //    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  //    column-gap: 1.44425187752744vw;
  //    .card {
  //      width: 24.09012131715771vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 6.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/expoAi.png");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: bold;
  //          color: #339999;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: 400;
  //          color: #339999;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #eeeeee linear-gradient(to right, #ecfbe9 0%, #f0f6ff 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #339999;
  //          font-size: 0.92432120161756vw;
  //        }
  //        .bottom:hover {
  //          background: #2f6eff;
  //          color: white;
  //        }
  //      }
  //    }
  //    .card:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //    .card2 {
  //      width: 24.09012131715771vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 5.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/infoAi.png");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: bold;
  //          color: #2168db;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: 400;
  //          color: #2168db;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #eeeeee linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #2168db;
  //          font-size: 0.92432120161756vw;
  //        }
  //        .bottom:hover {
  //          background: #2f6eff;
  //          color: white;
  //        }
  //      }
  //    }
  //    .card2:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //
  //    .card3 {
  //      width: 24.09012131715771vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 6.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/jobAi.png");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: bold;
  //          color: #18a6ab;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: 400;
  //          color: #18a6ab;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #eeeeee linear-gradient(to right, #d7f4fa 0%, #f0f6ff 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #18a6ab;
  //          font-size: 0.92432120161756vw;
  //        }
  //      }
  //    }
  //    .card3:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //  }
  //}
  //.screen5Plus{
  //  left: 10.86077411900635vw;
  //  position: absolute;
  //  top: 223.9962917181706vh;
  //  margin: 0 auto;
  //  width: 78.22068168688619vw;
  //  background: rgba(184, 223, 246, 0.4);
  //  box-shadow: 5px 0 2px 0px #D4E4FF;
  //  height: fit-content;
  //  border-radius: 9px;
  //  padding: 4.0242274412855vh 1.09763142692085vw;
  //  .title{
  //    margin: 0 0.8vw;
  //    height: 6.05686032138443vh;
  //    opacity: 1;
  //    font-size: 1.67533217793183vw;
  //    //color: #53A3F7;
  //    font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //    font-weight: 700;
  //    line-height: 2.71940667490729vh;
  //  }
  //  .screen4 {
  //    display: grid;
  //    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  //    column-gap: 1.44425187752744vw;
  //    .card {
  //      width: 37.55054881571346vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 35.946847960445vh;
  //        width: 100%;
  //        position: relative;
  //        .logo {
  //          border-radius: 9px 9px 9px 9px;
  //          width: 100%;
  //          height: 17.42892459826947vh;
  //          background-image: url("../assets/mogu.jpg");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: bold;
  //          text-align: center;
  //          //color: #2168DB;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin: 4.71940667490729vh  1.32871172732525vw;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: 400;
  //          //color: #339999;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #EEEEEE linear-gradient(to bottom, #CFE1FF 0%, #E5EFFF 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #339999;
  //          font-size: 0.92432120161756vw;
  //        }
  //        .bottom:hover {
  //          background: #2f6eff;
  //          color: white;
  //        }
  //      }
  //    }
  //    .card:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //    .card2 {
  //      width: 37.55054881571346vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 5.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/mogu.jpg");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: bold;
  //          color: #2168db;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: 400;
  //          color: #2168db;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #EEEEEE linear-gradient(to bottom, #CFE1FF 0%, #E5EFFF 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #2168db;
  //          font-size: 0.92432120161756vw;
  //        }
  //        .bottom:hover {
  //          background: #2f6eff;
  //          color: white;
  //        }
  //      }
  //    }
  //    .card2:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //
  //    .card3 {
  //      width: 24.09012131715771vw;
  //      height: 100%;
  //      padding: 1.11248454882571vh 0.51993067590988vw;
  //      border-radius: 9px 9px 9px 9px;
  //      opacity: 1;
  //      cursor: pointer;
  //      //border: 2px solid #FFFFFF;
  //      .top {
  //        height: 26.946847960445vh;
  //        width: 100%;
  //        padding: 2.4610630407911vh 1.79087232813403vw;
  //        position: relative;
  //        .logo {
  //          position: absolute;
  //
  //          right: 2.00008464681845vw;
  //          top: 4.07354758961681vh;
  //          width: 6.54592720970537vw;
  //          height: 4.3263288009888vh;
  //          background-image: url("../assets/jobAi.png");
  //          background-size: 100% auto;
  //          background-position: center;
  //          background-repeat: no-repeat;
  //          background-color: transparent; /* 这里设置背景颜色为透明 */
  //          opacity: 1;
  //          //border: black 1px solid;
  //        }
  //        .title1 {
  //          height: 2.71940667490729vh;
  //          font-size: 0.97094165222415vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: bold;
  //          color: #18a6ab;
  //          line-height: 5.31520395550062ch;
  //        }
  //        .title2 {
  //          //text-align: justify;
  //          margin-top: 4.71940667490729vh;
  //          height: fit-content;
  //          font-size: 0.80878105141537vw;
  //          font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //          font-weight: 400;
  //          color: #18a6ab;
  //          line-height: 2.96662546353523vh;
  //        }
  //      }
  //      .back {
  //        margin: auto 0 0 0;
  //        width: 100%;
  //        height: 100%;
  //        background: #eeeeee linear-gradient(to right, #d7f4fa 0%, #f0f6ff 100%);
  //        border-radius: 9px 9px 9px 9px;
  //        opacity: 1;
  //        border: 2px solid #ffffff;
  //        .bottom {
  //          width: 100%;
  //          height: 5.06798516687268vh;
  //          background: white;
  //          border-radius: 0px 0px 9px 9px;
  //          opacity: 1;
  //          padding-left: 1.32871172732525vw;
  //          padding-right: 1.32871172732525vw;
  //          display: flex;
  //          justify-content: space-between;
  //          align-items: center;
  //          color: #18a6ab;
  //          font-size: 0.92432120161756vw;
  //        }
  //      }
  //    }
  //    .card3:hover {
  //      transform: scale(1.05);
  //      transition-duration: 0.3s;
  //      .bottom {
  //        background: #2f6eff;
  //        color: white;
  //      }
  //    }
  //  }
  //}
  //.screen5 {
  //  position: absolute;
  //  top: 286.2138442521632vh;
  //  width: 100%;
  //  height:fit-content;
  //  background-image: url("../assets/AIScreen.jpg");
  //  background-size: cover;
  //  background-position: center;
  //  background-repeat: no-repeat;
  //  background-color: transparent; /* 这里设置背景颜色为透明 */
  //  .title {
  //    margin-top: 12.84301606922126vh;
  //    text-align: center;
  //    height: 8.89987639060569vh;
  //    font-size: 3.5060658579vw;
  //    font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
  //    font-weight: 400;
  //    //color: #2F6EFF;
  //    line-height: 2.84301606922126vh;
  //  }
  //}


}
</style>

