<template>
  <div>
    <div v-if="isMobile===false">
      <el-dialog
          :visible.sync="dialogVisible"
          width="350px"
          :before-close="handleClose"
          top="35vh"
      >
        <div>
          <div class="title1"><span style="color:#2F6EFF">Submit Success！</span></div>
          <div class="title2">We will contact you as soon as possible!</div>
          <div style="text-align: center;margin-top: 30px">  <el-button type="primary" @click="handleClose">Confirm</el-button></div>

        </div>
      </el-dialog>
    </div>
    <div v-if="isMobile===true" style="font-size: 14px" class="phone">
      <el-dialog
          :visible.sync="dialogVisible"
          width="350px"
          :before-close="handleClose"
      >
        <div>
          <div class="title1"><span style="color:#2F6EFF">Submit Success！</span></div>
          <div class="title2" >We will contact you as soon as possible!</div>
          <div style="text-align: center;">  <el-button style="width: 100px" type="primary" @click="handleClose">Confirm</el-button></div>

        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  }, data(){
    return{
      token:localStorage.getItem("access_token"),
      isMobile:false,
      languageSelect:'',
      ruleForm: {
        companyName: '',
        userName: '',
        contact: '',
        content: '',
        consultType:1,
        consultUrl:'',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },

    }
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }

  },
  methods: {
    handleClose() {
      this.ruleForm.companyName=""
      this.ruleForm.userName=""
      this.ruleForm.contact=""
      this.ruleForm.content=""
      this.$emit('hideDialog')
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
};
</script>
<style scoped lang="scss">

.title1{
  text-align: center;
  width: 100%;
  font-size: 25px;
  margin-top: -45px;
}
.title2{
  color:#111111;
  text-align: center;
  width: 100%;
  line-height: 32px;
  margin-top: 25px;
}
.phone{
  position: fixed;
  z-index: 9999999999;
  .title1{
    text-align: center;
    width: 100%;
    font-size:16px;
    margin-top: -45px;
  }
  .title2{
    color:#111111;
    text-align: center;
    width: 100%;
    margin:25px auto 0 auto;
    line-height: 32px;
  }
  ::v-deep .el-form-item__error{
    left: 15px !important;
    top:80%;
  }
  ::v-deep .el-textarea__inner{
    border: none !important;
    margin-top: 5px;
    height: 35px;
  }
  .el-form-item {
    margin-bottom: 10px !important;
  }
  .span{
    height: 1px;
    background-color: #e1d7d7;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  ::v-deep {
    .el-dialog__body {
      padding: 30px 20px 30px 20px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    .el-dialog__header {
      .el-dialog__title {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color:#2F6EFF;;
      border: #2F6EFF 1px solid;
      border-radius: 50%;
    }
    .el-input__inner {
      border:none !important;

    }
    .el-textarea {
      border-radius: 4px;
      line-height: 20px;
      border: none !important;
      position: relative;
      font-size: 14px;
    }
    .el-form-item__label {
      font-size: 14px;
    }
    .el-button {
      border: 1px solid #2F6EFF;
      a {
        display: block;
      }
    }
    .el-button--primary {
      border: 1px solid #2F6EFF;
      background: #2F6EFF;
      color: #fff;
    }

  }
  ::v-deep {
    .el-dialog{
      margin:auto !important;
      font-size: 14px;

      top: 250px;
    }
    .el-dialog__header {
      padding:5px;
      .el-dialog__title {
        font-weight: bold;
        padding:15px;
        font-size: 14px;

      }

    }


    .el-button {
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      padding:10px  15px;
      line-height:25px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      a {
        display: block;
      }
    }

  }

}
::v-deep {
  .el-dialog__body {
    padding: 30px 50px 30px 40px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-dialog__header {
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .el-dialog__headerbtn .el-dialog__close {
    color:#2F6EFF;;
    border: #2F6EFF 1px solid;
    border-radius: 50%;
  }
  .el-input__inner {
    border-radius: 5px !important;
    border: 1px solid #9b9a9a !important;

  }
  .el-textarea {
    border-radius: 5px !important;
    line-height: 40px;
    border: 1px solid #9b9a9a !important;
    position: relative;
    font-size: 14px;
  }
  .el-form-item__label {
    font-size: 16px;
  }
  .el-button {
    width: 150px;
    height: 45px;
    line-height: 35px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    color: #2F6EFF;
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid #2F6EFF;
    background: #2F6EFF;
    color: #fff;
  }

}
</style>
