<template>
  <div>
    <div v-if="model===false">
      <div class="screen">
        <div style="height:75%;display: flex;justify-content: space-between;padding:3.023609394314vh 10.97631426920855vw 3.023609394314vh 10.97631426920855vw ;">
          <div class="left">
            <div class="left3">
              <span style="cursor: pointer" @click="gptsProfessional()"> Oil & Gas Generic AI</span><br>
              <div @click="gptsDesign()" class="community">Design-oriented GPTs</div>
              <div @click="gptsTranslate()" class="community">Explanatory GPTs</div>
              <div @click="gptsTechnology()" class="community">Process-related GPTs</div>
            </div>
            <div class="left1">
              <span style="cursor: pointer" @click="gpt3">Oil & Gas Generic AI Community</span><br>
              <div @click="gpts1()" class="community">AI Technology Seminar</div>
              <div @click="gpts2()" class="community">Large Model Knowledge Base</div>
            </div>
            <div class="left2">
              <span>Contact Us</span><br>
              <div class="community1">Contact Us：<span class="community" @click="dialogVisible = true">Product Inquiry</span></div>
              <div class="community1">Contact Email：<a class="community" style="border-bottom: gray 1px solid" href="mailto:service@oilgasgpts.com">service@oilgasgpts.com</a></div>
            </div>
            <div class="left3">
              <span>Customer Care</span><br>
              <div class="community" @click="priacyPc">Privacy Policy</div>

            </div>
          </div>
          <div class="right">
            <div class="left2">
              <div class="up">
                <img src="@/assets/attention.png">
              </div>
              <div class="down">Mobile terminal</div>
            </div>
          </div>
        </div>
<!--        <div class="foot">-->
<!--          <div class="flex" style="padding-bottom:15px ">-->
<!--            <p>No. 11 Yingbin South Road, Huairou District, Beijing-->

<!--            </p>-->
<!--            <span> | </span>-->
<!--            <p>Copyright Beijing Shiyou Classmate Information Consulting Co., Ltd</p>-->
<!--&lt;!&ndash;            <span> | </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <p class="red">&ndash;&gt;-->
<!--&lt;!&ndash;              <a href="https://beian.miit.gov.cn/">京ICP备2022034763号-8</a>&ndash;&gt;-->
<!--&lt;!&ndash;            </p>&ndash;&gt;-->
<!--          </div>-->
<!--          <div-->
<!--              style="-->
<!--          width: fit-content;-->
<!--          font-size: 12px;-->
<!--          margin: 0 auto;-->
<!--          padding: 5px 0px 10px;-->
<!--        "-->
<!--          >-->
<!--            <a-->
<!--                target="_blank"-->
<!--                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502053484"-->
<!--                style="display: inline-block; text-decoration: none"-->
<!--            >-->
<!--              <img src="@/assets/beian.png" style="float: left; width: 17px" />-->
<!--              <p-->
<!--                  style="-->
<!--              float: left;-->
<!--              height: 20px;-->
<!--              line-height: 20px;-->
<!--              margin: 0px 0px 0px 5px;-->
<!--              color: #939393;-->
<!--            "-->
<!--              >-->
<!--                京公网安备 11010502053484号-->
<!--              </p></a-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <div v-else>
      <div class="screenModel" ref="screenModel">
        <div class="foot">
          <div class="left1">
            <span @click="gptsProfessional1()" style="color: white;font-size: 14px">Oil & Gas Professional GPTs</span>
            <span @click="hidden3" v-if="hid2===0" style="margin-top:-5px;float: right;color: white"><i class="el-icon-caret-top"></i> </span>
            <span @click="hidden23" v-if="hid2===1" style="margin-top:-5px;float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
            <br>
            <div v-if="hid2===0" @click="gptsDesign1()" class="community">Design-oriented GPTs</div>
            <div v-if="hid2===0" @click="gptsTranslate1()" class="community">Explanatory GPTs</div>
            <div v-if="hid2===0" @click="gptsTechnology1()" class="community">Process-related GPTs</div>
          </div>

            <div class="left1">
              <span @click="gpts13()" style="color: white;font-size: 14px">Oil & Gas Generic AI Community</span>
              <span @click="hidden" v-if="hid===0" style="margin-top:-5px;float: right;color: white"><i class="el-icon-caret-top"></i> </span>
              <span @click="hidden2" v-if="hid===1" style="margin-top:-5px;float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
              <br>
              <div v-if="hid===0" @click="gpts11()" class="community"> AI Technology Seminar</div>
              <div v-if="hid===0" @click="gpts21()" class="community"> Large Model Knowledge Base</div>
            </div>
            <div class="left2">
              <span style="color: white;font-size: 14px">Contact Us</span>
              <span @click="hidden1" v-if="hid1===0" style="margin-top:-5px;float: right;color: white"><i class="el-icon-caret-top"></i> </span>
              <span @click="hidden12" v-if="hid1===1" style="margin-top:-5px;float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
              <br>
              <div v-if="hid1===0" class="community">Contact Us：<span @click="dialogVisible = true">Product Consultation</span></div>
              <div v-if="hid1===0" class="community">Contact Email：<a href="mailto:service@oilgasgpts.com">service@oilgasgpts.com</a></div>
            </div>
          <div class="left2" style="padding-bottom: 25px">
            <span style="color: white;font-size: 14px">Customer Care</span>
            <span @click="hidden4" v-if="hid4===0" style="margin-top:-5px;float: right;color: white"><i class="el-icon-caret-top"></i> </span>
            <span @click="hidden42" v-if="hid4===1" style="margin-top:-5px;float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
            <br>
            <div v-if="hid4===0" class="community" @click="priacy()">Privacy Policy</div>

          </div>
<!--          <div class="left3">-->
<!--          </div>-->
<!--          <div class="flex">-->
<!--            <p>No. 11 Yingbin South Road, Huairou District, Beijing</p>-->
<!--          </div>-->
<!--          <div class="flex">-->
<!--            <p>Copyright Beijing Shiyou Classmate Information Consulting Co., Ltd</p>-->
<!--          </div>-->
<!--          <div-->
<!--              style="-->
<!--          width: fit-content;-->
<!--          font-size: 12px;-->
<!--          margin: 0 auto;-->
<!--          padding: 5px 0px 10px;-->
<!--        "-->
<!--          >-->
<!--            <a-->
<!--                target="_blank"-->
<!--                style="display: inline-block; text-decoration: none"-->
<!--            >-->
<!--              <p-->
<!--                  style="-->
<!--              float: left;-->
<!--              height: 20px;-->
<!--              line-height: 20px;-->
<!--              margin: 0px 0px 0px 5px;-->
<!--              color: #939393;-->
<!--            "-->
<!--              >-->
<!--                京ICP备2022034763号-8 &nbsp;&nbsp;京公网安备 11010502053484号-->
<!--              </p></a-->
<!--            >-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <consultTip
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
    ></consultTip>
  </div>

</template>


<script>

import consultTip from "@/components/consultTip.vue";

export default {
  name: "",
  props: [],
  components: {consultTip},

  data() {

    return {
      hid:0,
      hid1:0,
      hid2:0,
      hid4:0,
      dialogVisible: false,
      model:false,
    }

  },
  beforeRouteLeave(to, from, next) {
    // 保存滚动位置
    this.scrollPosition = this.$refs.screenModel.scrollTop;
    next();
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 恢复滚动位置
      vm.$nextTick(() => {

        vm.$refs.screenModel.scrollTop = vm.scrollPosition;

      });
    });
  },
  methods: {
    hidden(){
      this.hid=1
    },
    hidden2(){
      this.hid=0
    },
    hidden1(){
      this.hid1=1
    },
    hidden12(){
      this.hid1=0
    },
    hidden4(){
      this.hid4=1
    },
    priacyPc(){
      window.open("/privacy")
    },
    priacy(){
      this.$router.push("/privacy")
    },
    hidden42(){
      this.hid4=0
    },
    hidden3(){
      this.hid2=1
    },
    hidden23(){
      this.hid2=0
    },
    gpts1(){
      window.open("https://www.oilgascommunity.com/sortList?id=40", "_blank");
    },
    gpts2(){
      window.open("https://www.oilgascommunity.com/sortList?id=41", "_blank");
    },
    gpt3(){
      window.open("https://www.oilgascommunity.com/sortList?id=39", "_blank");
    },
    gptsProfessional(){
      sessionStorage.setItem("skip", 4)
      console.log(localStorage.getItem("home"))
      if (localStorage.getItem("home")==="1") {
        window.location.reload()
      } else {
        window.open("/", "_blank");
      }
    },
    gptsDesign(){
      window.open("/gptDesign", "_blank");
    },
    gptsTranslate(){
      window.open("/gptTranslate", "_blank");
    },
    gptsTechnology(){
      window.open("/gptTechnology", "_blank");
    },

    gptsProfessional1(){
      sessionStorage.setItem("skip", 5)
      if (localStorage.getItem("home")==="1") {
        window.location.reload()
      } else {
        this.$router.push("/");
      }



    },
    gptsDesign1(){
      localStorage.setItem("skip", 3)
      this.$router.push("/gptOil");
    },
    gptsTranslate1(){
      localStorage.setItem("skip", 3)
      this.$router.push("/gptTranslate");
    },
    gptsTechnology1(){
      localStorage.setItem("skip", 3)
      this.$router.push("/gptHole",);
    },
    gpts11(){
      localStorage.setItem("skip", 0)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=40';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    gpts21(){
      localStorage.setItem("skip", 3)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=41';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    gpts13(){
      localStorage.setItem("skip", 3)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=39';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    hideDialog() {
      this.dialogVisible = false;
    },

  },

  mounted() {

      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
      console.log("Screen Width: ", screenWidth);
      console.log("Screen Height: ", screenHeight);

      if(screenWidth<=700){
        this.model=true
      }else{
        this.model=false
      }


  }

}
</script>


<style type="text/scss" lang="scss" scoped>
.screen{
  z-index: 9999999999999999999;
  width: 100%;
  height: fit-content;
  background-color: #333333;
  .foot {
    padding-top: 15px;
    text-align: left;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }
  .yuming{
    color:white;

  }

  .left{
    padding-top: 3vh;
    display: flex;
    color:#FFFFFF;
    .left1{
      margin-left: 2.15944540727903vw;
      width: fit-content;
      font-size: 0.8709416522vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw
      }
    }
    .left3{
      margin-left: 0.6932409012vw;
      width:fit-content;
      font-size: 0.8709416522vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw
      }
    }
    .left2{
      margin-left: 2.15944540727903vw;
      width: fit-content;
      font-size: 0.8709416522vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community1{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw
      }
    }

  }
  .right{
    margin-top: 2vh;
    display: flex;
    color:#FFFFFF;
    .left2{
      margin-left:      4.15944540727903vw;
      width: 7.23916811091854vw;
      font-size: 0.7709416522vw;
      font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 3.33745364647713vh;
      .up{
        margin:0 auto;
        width: 6.23916811091854vw;
        height: auto;
        //background-image:url("../assets/attention.png");
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat
      }
      .down{
        width:  7.23916811091854vw;
      }
    }

  }
}
.screenModel{
  width: 100%;
  height: fit-content;
  background-color: #333333;
  .left1{
    width: 90%;
    margin:0 15px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    .community{
      margin-left: 12px;
      color:gray;
      cursor: pointer;
      font-size:12px
    }

  }
  .left2{
    width: 90%;
    margin:0 15px;
    color: white;
    text-align: left;
    font-size: 14px;
    font-family: Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif;
    font-weight: 400;
    line-height: 32px;
    .community{
      margin-left: 12px;
      color:gray;
      cursor: pointer;
      font-size: 12px
    }
  }
  .left3{
  width: 100%;
    margin:15px 0;
    height: 1px;
    background-color: #484848;
  }

  .foot {
    padding-top: 15px;
    text-align: center;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }

}
</style>
